import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout } from 'antd';

//componentes
import Login from '../Components/Auth/Login';
import UpdatePassword from "../Components/Auth/UpdatePassword";
import ThemeEdit from '../Components/ThemeEdit/index'
import Landing from '../Components/Public/Landing'
import Cotizador from '../Components/Public/Cotizador'

import '../Styles/Modules/Customer/Resumen/Resumen.css'
import Terminos from '../Components/Public/Terminos';


// import '../Styles/Theme/Public/antd-zeus-public-theme.css'

/**
 * 
 * @function PublicRoutes
 * @description Router para rutas publicas
 * @export
 */
function PublicRoutes({ setUser }) {

  useEffect(() => {
    // require('../Styles/Theme/Public/antd-zeus-public-theme.css')
  }, [])

  return (
    <Layout>
      <Routes>
        <Route
          path="recovery/:token"
          element={<Landing />}
        />
        <Route
          path=""
          element={<Landing />}
        />
        <Route
          path="tyc"
          element={<Terminos />}
        />

        <Route
          path="login"
          element={<Login />}
        />
        <Route
          path="theme"
          element={<ThemeEdit />}
        />

      </Routes>
    </Layout>
  )
}

export default PublicRoutes;