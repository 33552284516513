import React, { Component, useContext } from 'react';
import { Spin, PageHeader, Layout, message, Button, List, Row, Card, Col, Tag, Typography, Input, Tabs, Pagination, Result, Space } from 'antd'
import axios from 'axios';
import io from "socket.io-client";

import { CardProducto, CardProductoVenta } from '../../Widgets/Cards';
import './../../../Styles/Modules/POS.css'
import { User } from '../../../Hooks/Logged';
import Socket from '../../../Hooks/Socket';
import DrawerClientes from './DrawerClientes';
import ModalCliente from '../CRM/ModalCliente';
import 'moment/locale/es';
import moment from "moment";
import { UserAddOutlined, SmileOutlined, FrownOutlined, UserDeleteOutlined } from '@ant-design/icons';

moment.locale('es');

const { Content } = Layout;
const { Text, Title } = Typography
const { TabPane } = Tabs

const venta_status = {
    1: 'Pagar',
    2: 'Pagado',
    3: 'Rechazado'
}
/**
 * @export
 * @class POS
 * @extends {Component}
 * @description Vista de POS Principal
 */
class POS extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            update: false,
            is_pay: false,
            //productos del pos
            productos: {
                loading: false,
                page: 1,
                total: 0,
                data: [],
                categoria_id: null,
                search: ''
            },

            categorias: [],


            //almaceno la venta generada para la orden
            venta: {
                cliente_id: null,
                detalles: []
            },

            //data modal
            modal_visible: false,
            cliente_id: undefined,

            loadingPayment: false,

        }

    }

    //inicio conexion
    
    DrawerRef = React.createRef();
    /**
     * 
     * @method componentDidMount
     * @description Obtenemos las haciendas en cuestión
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCategorias()


        // inicio el intercambio de datos
        this.props.socket.emit("start")

        //cargo productos de la venta
        this.props.socket.on('successful', this.IO_start)

        //al recibir la lista de productos, actualizo la venta
        this.props.socket.on('list_productos', this.IO_getProductos)
        //al pagar
        this.props.socket.on('payment', this.setPayment)

        //al asignar, quitar cliente a la venta
        this.props.socket.on('get_cliente', this.IO_loadCliente)

        //por si ocurre un error
        this.props.socket.on('error', this.IO_Error)
    }

    /**
     *
     *
     * @memberof POS
     * @method IO_Error
     * @description Muestra los errores que pudieran ocurrir en el socket 
     */
    IO_Error = (response) => {
        message.error(response.message)
        this.setState({ update: false })

    }
    /**
     *
     *
     * @memberof POS
     * @method IO_getPOS
     * @description MAnda la peticion de la lista del POS por socket 
     */
    IO_getPOS = (
        {
            page = this.state.productos.page,
            categoria_id = this.state.productos.categoria_id,
            search = this.state.productos.search,

        } = this.state.productos,
        { productos } = this.state,
    ) => {
        this.setState(state => {
            state.productos.loading = true
            return state;
        })
        this.props.socket.emit('get_data_pos', { page, limit: 10, search, categoria_id })
        this.props.socket.on('data_pos', this.getProductos)
    }

    /**
     * @memberof POS
     * @method getProductos
     * @description Guarda ls lista de Productos del POS en el state
    */
    getProductos = (response) => {

        let list = response.itemsList;
        let paginator = response.paginator
        this.setState(state => {
            state.productos.data = (state.productos.page === 1) ? list : [
                ...list
            ]
            state.productos.total = paginator.itemCount
            state.productos.page = paginator.currentPage
            state.productos.loading = false
            return state;
        })

    }

    /**
    * @method POS
    * @description Obtiene ls lista de Categorias
    */
    getCategorias = () => {
        axios.get('/categorias', {
            params: {
                paginate: false
            }
        }).then(response => {

            this.setState({
                categorias: response.data.data,
            })
        }).catch(error => {

            message.error('Error al obtener la Información')
        })
    }

    /**
     *
     *
     * @memberof POS
     * @description Guarda la venta al iniciar la sesion y trae la lista de productos de la venta
     */
    IO_start = (data) => {
        console.log('se data', data)

        this.setState({ venta: data, update: false })
        console.log('se falseo')
        //cargo los productos del post
        this.IO_getPOS();
        this.props.socket.emit('get_productos', data._id)//solicito la lista de productos de la venta


    }

    /** 
     *
     *
     * @memberof POS
     * @description Guarda en el state la venta para actualizar la vista
     */
    IO_getProductos = (data) => {

        if (this.DrawerRef.current != null) {
            this.DrawerRef.current.setCliente(data.cliente_id);
        }
        this.setState({ venta: { ...data }, update: false })
        console.log('se falseo')
    }

    /**
     *
     *
     * @memberof POS
     * @description Añade un producto a la venta  trae la lista de productos
     */
    IO_addProductos = (producto) => {
        this.setState({ update: true })

        let venta = this.state.venta;


        //validar que no este añadido previamente a la venta
        let exists = venta.detalles.findIndex(p => p.variante_id._id === producto._id);
        if (exists != -1) {
            message.warning('El producto ya ha sido añadido a la venta')
            this.setState({ update: false })
        }
        else
            this.props.socket.emit('new_producto', { venta_id: venta._id, producto: producto })


    }

    /**
     *
     *
     * @memberof POS
     * @description Elimina el producto y trae la lista de productos
     */
    IO_deleteProductos = (detalle) => {

        this.setState({ update: true })

        let venta = this.state.venta;
        this.props.socket.emit('delete_producto', venta._id, detalle)
    }

    /**
     *
     *
     * @memberof POS
     * @description Actualiza el producto y trae la lista de productos
     */
    IO_updateProductos = (detalle, cantidad) => {


        this.setState({ update: true })
        let venta = this.state.venta;
        this.props.socket.emit('update_producto', { venta_id: venta._id, detalle: detalle, cantidad: cantidad })
    }

    /**
     *
     *
     * @memberof POS
     * @description Asigna a la venta un cliente existente
     */
    IO_updateCliente = (values) => {

        let venta = this.state.venta;
        this.setState({ update: true, drawer_visible: false })
        this.props.socket.emit('add_cliente', { venta_id: venta._id, cliente_id: values.cliente })
    }

    /**
    *
    *
    * @memberof POS
    * @description Asigna a la venta un cliente existente
    */
    IO_removeCliente = () => {

        let venta = this.state.venta;
        this.setState({ update: true, drawer_visible: false })
        this.props.socket.emit('remove_cliente', { venta_id: venta._id })
    }

    /**
     *
     *
     * @memberof POS
     * @description Muestra los mensajes correspondiente si se añadio al usuario
     */
    IO_loadCliente = (success) => {

        if (success) {
            message.success('Se actualizo Cliente de Venta')

        }
        if (!success) {
            message.error('Ocurrio un error al actualizar al Cliente')
        }
        this.refresh()
    }

    /**
    *
    *
    * @memberof POS
    * @description Envia la peticion para realizar el pago al socket
    */
    IO_Payment = () => {
        let venta = this.state.venta;
        if (venta.detalles.length > 0) {
            this.setState({ loadingPayment: true })
            this.props.socket.emit('pay_cash', venta._id, sessionStorage.getItem('token'))
        }
        else
            message.warning('No ha seleccionado ningun producto')
    }

    /**
     *
     *
     * @memberof POS
     * @description Actualizo la venta y el estatus de pagado   
     */
    setPayment = (data) => {
        this.setState({ venta: { ...data }, is_pay: true, loadingPayment: false })
        let url = new URL(axios.defaults.baseURL + '/ventas/ticket')
        url.searchParams.append('venta_id', data._id)
        url.searchParams.append('Authorization', sessionStorage.getItem('token'))
        window.open(url, '_blank')
    }

    /**
     *
     *
     * @memberof POS
     * @description Manda la peticion para generar una venta
     */
    IO_newVenta = () => {
        console.log('se trueleo');
        this.setState({ is_pay: false, update: true, venta: { cliente_id: null } })
        this.props.socket.emit('new_venta')
    }

    /**
    *
    *
    * @memberof POS
    * @description Manda a llamar al socket para actualizar los datos 
    */
    refresh = () => {

        let venta = this.state.venta;

        this.setState({ loading: true })
        this.props.socket.emit('get_productos', venta._id)
    }

    /**
     *
     *
     * @memberof pos
     * @description Abre o cierra el drawer
     */
    toogleDrawer = () => {
        this.setState(prevState => ({ drawer_visible: !prevState.drawer_visible }))
    }

    /**
     *
     *
     * @memberof POS
     * @description Renderiza el Result correspondiente al status de la venta
     */
    renderResult = () => {
        let { venta } = this.state;
        if (venta.status == 2) {
            return <Result
                icon={<SmileOutlined />}
                title={"Compra Finalizada"}
                extra={<Button type="primary" onClick={this.IO_newVenta}>Continuar</Button>}
            />
        }
        else if (venta.status == 3) {
            return <Result
                style={{ minHeight: '600px' }}
                icon={<FrownOutlined />}
                title={"Ocurrio un Error al Generar Cobro"}
                extra={<Button type="primary" onClick={this.IO_Payment}>Volver a Intentar</Button>}
            />
        }

    }

    render() {
        const user = this.props.user;
        let name_user = (user.nombres != undefined) ? user.nombres : '';
        let last_name = (user.apellido_paterno != undefined) ? user.apellido_paterno : '';
        let last_name2 = (user.apellido_materno != undefined) ? user.apellido_materno : '';

        console.log('state',this.state);
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Punto de Venta"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={16}>
                            <Row>
                                <Col xs={24} md={14}>
                                    <Title level={2} className="m-0 text-cyan-dark">{`${name_user} ${last_name} ${last_name2}`}</Title>
                                    <Text className="text-cyan-dark">{moment().format('dddd, LL')} </Text>
                                </Col>
                                <Col xs={24} md={10} className="flex-left">
                                    <Input.Search
                                        className="input-search-product"
                                        addonAfter={null}
                                        suffix={null}
                                        placeholder="Nombre"
                                        allowClear
                                        size="large"
                                        onSearch={(value) => this.IO_getPOS({ search: value })}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xs={24} md={18}>
                                    <Title level={3}>Escoge los Productos</Title>
                                </Col>
                                <Col xs={24} md={6} className="flex-right">
                                    <Space>
                                        <Button onClick={this.toogleDrawer} className="btn-add-client" icon={<UserAddOutlined style={{ color: 'currentColor' }} />}>
                                            {this.state.venta.cliente_id != undefined ? this.state.venta.cliente_id.usuario_id.nombres : 'Añadir Cliente'}
                                        </Button>
                                        {this.state.venta.cliente_id != undefined ?
                                            <Button icon={<UserDeleteOutlined />} onClick={this.IO_removeCliente} ></Button> : null}
                                    </Space>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className="mt-1">
                                    <Tabs defaultActiveKey="0"  className="tabs-categorias" onChange={(key) => this.IO_getPOS({ categoria_id: key })}>
                                        <TabPane tab="Todas" key={0}></TabPane>
                                        {this.state.categorias.map(categoria => <TabPane tab={categoria.nombre} key={categoria._id}></TabPane>)}
                                    </Tabs>
                                </Col>
                            </Row>
                            <Spin spinning={this.state.productos.loading}>
                                <Row gutter={[0, 50]} className="products-container" >
                                    {this.state.productos.data?.map((producto, index) => <Col xs={24} sm={12} md={8} xxl={6} key={producto.variante_id._id}>
                                        <CardProducto
                                            precio={producto.variante_id?.precio}
                                            libres={producto.variante_id?.disponible}
                                            imagenes={producto.imagenes}
                                            nombre={producto.nombre}
                                            descripcion={producto.descripcion}
                                            variante={producto.variante_id}
                                            sku={producto.variante_id.sku_variante}
                                            disponibles={producto.variante_id.inventario?.existencias}
                                            addProducto={this.IO_addProductos}
                                        />
                                    </Col>)}
                                </Row>
                                <Pagination
                                    current={this.state.productos.page}
                                    pageSize={10}
                                    total={this.state.productos.total}
                                    showSizeChanger={false}
                                    hideOnSinglePage={false}
                                    position='bottom'
                                    onChange={(page) => this.IO_getPOS({ page })}
                                    className="flex-left pd-1"
                                />


                            </Spin>

                        </Col>

                        <Col xs={24} md={8}>
                            <Card className="card-venta" title={<Title level={4} className="m-0 text-cyan-dark">Orden #ID {this.state.venta?._id}</Title>}  >
                                {/* TITULO */}
                                <Row>
                                    <Col span={24} className="mb-1">
                                        <Tag color={"#16B1C2"}>Punto de Venta</Tag>
                                    </Col>
                                    <Col span={18} className="divder-top">
                                        Producto
                                    </Col>
                                    <Col span={6} className="divder-top">
                                        Cant.
                                    </Col>
                                </Row>
                                {/* LISTA DE PRODUCTOS */}
                                {!this.state.is_pay ?
                                    <Spin spinning={this.state.update}>
                                        <Row className="products-container">
                                            <List
                                                header={null}
                                                footer={null}
                                                className="width-100"
                                                dataSource={this.state.venta?.detalles}
                                                renderItem={detalle => {
                                                    let variante = detalle.variante_id;
                                                    let producto = detalle.variante_id?.producto_id;
                                                    return <List.Item>
                                                        <CardProductoVenta
                                                            id={detalle._id}
                                                            detalle_id={detalle?._id}
                                                            nombre={producto?.nombre}
                                                            descripcion={variante.descripcion}
                                                            precio={variante?.precio}
                                                            cantidad={detalle.cantidad}
                                                            imagenes={producto?.imagenes}
                                                            deleteProducto={this.IO_deleteProductos}
                                                            onChangeCantidad={this.IO_updateProductos}
                                                        />
                                                    </List.Item>
                                                }}
                                            />

                                        </Row>

                                        {/* MONTOS DE VENTA */}
                                        <Row>
                                            <Col span={18} className="mb-05"><Text>Subtotal</Text></Col>
                                            <Col span={6}><Text className="text-cyan">$ {this.state.venta.subtotal?.toMoney && this.state.venta.subtotal?.toMoney(true)}</Text></Col>
                                            <Col span={18} className="mb-1"><Text>IVA (% {this.state.venta?.iva})</Text></Col>
                                            <Col span={6} className="text-cyan">$ {((this.state.venta?.total - this.state.venta?.subtotal > 0) ? (this.state.venta?.total - this.state.venta?.subtotal).toMoney() : 0.00)}</Col>
                                            <Col span={18} className="mb-1"><Text>Total </Text></Col>
                                            <Col span={6}><Text className="text-cyan">$ {this.state.venta?.total?.toMoney && this.state.venta?.total?.toMoney(true)}</Text> </Col>
                                        </Row>
                                        <Row className='width-100'>
                                            <Button type="secondary" className='btn-payment' block onClick={this.IO_Payment} loading={this.state.loadingPayment}>
                                                $ {venta_status[this.state.venta.status]}  {(this.state.venta?.total?.toMoney && this.state.venta?.total?.toMoney(true)) ?? 0}

                                            </Button>
                                        </Row>
                                    </Spin>
                                    :
                                    this.renderResult()
                                }
                            </Card>
                        </Col>
                    </Row>


                </Content>
                <DrawerClientes
                    visible={this.state.drawer_visible}
                    cliente={this.state.venta.cliente_id}
                    onClose={this.toogleDrawer}
                    ref={this.DrawerRef}
                    addCliente={this.IO_updateCliente}
                    newCliente={() => this.setState({ cliente_id: undefined, modal_visible: true, drawer_visible: false })}
                    editCliente={(e) => this.setState({ cliente_id: e.target.id, modal_visible: true, drawer_visible: false }, this.refresh)}
                />

                <ModalCliente
                    visible={this.state.modal_visible}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.refresh()
                        this.setState({ modal_visible: false, drawer_visible: true })
                    }}

                />
            </>
        )
    }
}

export default function (props) {

    let user = useContext(User)
    let socket = useContext(Socket)
    return <POS {...props} user={user} socket={socket} />
}
