import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Tooltip, Layout, Space, message, List, Card, Switch } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios';
import { IconBtnDelete, IconBtnEdit, IconDown, IconEye } from '../../Widgets/Iconos'
import { WarningOutlined } from '@ant-design/icons'
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton'

import ModalProducto from './ModalProducto';
const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista donde se listan todos los Productos
 */
export default class Productos extends Component {



    /**
     *Creates an instance of Productos.
     * @param {*} props
     * @memberof Productos
     */
    constructor(props) {
        super(props)
        this.state = {
            productos: [],
            page: 1,
            limit: 10,
            total: 0,

            producto_selected: null,
            search: this.props.search
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getProductos();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.setState({ search: this.props.search }, () => this.getProductos())
        }
    }

    /**
    * @method getProductos
    * @description Obtiene ls lista de Productos
    */
    getProductos = (page = this.state.page) => {
        
        this.setState({ loading: true })
        axios.get('/productos', {
            params: {
                limit: 10,
                page: page,
                search: this.state.search
            }
        }).then(response => {
            
            this.setState({
                productos: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })
        }).catch(error => {
            
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }




    render() {


        return (
            <>
                <Spin spinning={false}>
                    <PageHeader className="admin-page-header" title="Productos" />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Productos" }}
                            dataSource={this.state.productos}
                            pagination={{
                                current: this.state.page,
                                pageSize: 10,
                                onChange: page => this.getProductos(page),
                                total: this.state.total,
                                position: 'bottom',
                                className: "flex-left"

                            }}
                            header={<Row className="width-100" gutter={[0, 8]} >
                                <Col xs={24} md={5} className="center" >
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={24} md={6} className="center" >
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col xs={8} md={2} className="center" >
                                    <Text strong>Variantes</Text>
                                </Col>
                                <Col xs={8} md={4} className="center" >
                                    <Text strong>Categoría</Text>
                                </Col>
                                <Col xs={8} md={2} className="center" >
                                    <Text strong>Activo</Text>
                                </Col>

                                <Col xs={24} md={5} className="center" >
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className={"component-list-item"}>
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[0, 12]}>
                                            <Col xs={24} md={5} className="center" >
                                                <Text strong>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} md={6} className="center" >
                                                <Text >{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={8} md={2} className="center" >
                                                <Space size={10}>
                                                    <Text >{item.variantes.length}</Text>
                                                </Space>
                                            </Col>
                                            <Col xs={8} md={4} className="center" >
                                                <Text >{item.categoria?.nombre ? item.categoria?.nombre : 'Sin Categoría'}</Text>
                                            </Col>
                                            <Col xs={8} md={2} className="center" >
                                                <Switch checked={item.activo} disabled />
                                            </Col>
                                            <Col xs={24} md={5} xxl={{span:3}} className="center">
                                                <Space wrap={true} align="center" direction="horizontal">
                                                    <Link to={`/admin/productos/${item._id}/variantes`} >
                                                        <Button type='primary' className='btn-primary' icon={<IconEye />} />
                                                    </Link>
                                                    <Button type='primary' className='btn-secondary' icon={<IconBtnEdit />} onClick={() => this.setState({ modal_visible: true, producto_selected: item._id })} />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este producto?"
                                                        onConfirm={() => axios.delete('/productos/delete', { params: { id: item._id } }).then((response) => {
                                                            message.success(response?.data?.message)
                                                            this.getProductos()
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message);
                                                                this.getProductos();
                                                            })
                                                        }
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" danger className='btn-delete' title="Eliminar" icon={<IconBtnDelete />} />
                                                    </Popconfirm>
                                                    {item.escaso ? <Tooltip title="Pocas Existencias">
                                                        <WarningOutlined style={{ color: '#ffd500', fontSize: 20 }} />
                                                    </Tooltip>
                                                        : null}

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                </Spin>
                <FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modal_visible: true, producto_selected: null })} />
                <ModalProducto
                    visible={this.state.modal_visible}
                    id={this.state.producto_selected}
                    onClose={() => {
                        this.setState({ modal_visible: false, producto_selected: null })
                        this.getProductos(this.state.page)
                    }}
                />
            </>
        )
    }
}
