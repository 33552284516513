import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, message, Empty, Spin, Radio, Carousel as AntCarousel, Image, Space, Menu, List, Avatar } from 'antd';
import Carousel from 'react-multi-carousel';
import axios from 'axios'

import ModalHacienda from './ModalHacienda'
import { CardHacienda } from '../../Widgets/Cards'
import { IconAgabeColor, IconClock, IconSolar, IconPlace } from '../../Widgets/Iconos'

import Mapa from '../../Widgets/Mapa/Mapa'
import 'react-multi-carousel/lib/styles.css';
import Gallery from "../../Widgets/Gallery/Gallery";
import ListaReventas from "./ListaReventas";


import "../../../Styles/Modules/Customer/Inversion.scss";
import { FilePdfOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import EdadCounter from "./EdadCounter";


const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const moment = require('moment')

const momentPreciseRangePlugin = require('moment-precise-range')(moment);


/**
 *
 *
 * @export
 * @class Inversion
 * @extends {React.Component}
 */
export default class Inversion extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            haciendas: [],
            hacienda: {},
            page: 1,
            total: 0,
            limit: 10,
            ubicacion: false,
            preview: 'imagenes'
        }
    }

    responsive = {
        desktop_fhq: {
            breakpoint: { max: 4000, min: 1500 },
            items: 5
        },
        desktop_hq: {
            breakpoint: { max: 1500, min: 1200 },
            items: 4
        },
        desktop_xxl: {
            breakpoint: { max: 1200, min: 992 },
            items: 3
        },
        desktop_xl: {
            breakpoint: { max: 992, min: 768 },
            items: 3
        },
        desktop_lg: {
            breakpoint: { max: 768, min: 576 },
            items: 2
        },
        desktop_md: {
            breakpoint: { max: 576, min: 464 },
            items: 1
        },
        desktop_sm: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getHaciendas()
    }

    /**
    * @method getHaciendas
    * @description Obtiene ls lista de haciendas y pone la primera en el state
    */
    getHaciendas = (page = this.state.page, limit = this.state.limit) => {
        this.setState({ loading: true })
        axios.get('/haciendas', {
            params: {
                page,
                limit
            }
        }).then(response => {

            let haciendas = response.data.data.data
            let hacienda = {}
            if (haciendas.length > 0) {
                hacienda = haciendas[0]
            }

            let state = { haciendas }

            if (!this.state.hacienda?._id)
                state["hacienda"] = hacienda
            this.setState(state)

        }).catch(error => {

            message.error('Error al obtener las Haciendas')

        })
            .finally(() => this.setState({ loading: false }))
    }




    render() {

        const { hacienda, preview } = this.state

        let has_mapa = (hacienda?.poligono?.polygon?.type === "FeatureCollection")
        let has_imagenes = hacienda?.imagenes?.length > 0

        return (
            <Spin spinning={this.state.loading}>
                <Content className="hm-layout-content layout-dashboard" >
                    <PageHeader className="site-page-header" ghost={false} title="Invertir" >
                    </PageHeader>
                    <Row gutter={[18, 18]} className="mt-1">
                        <Col span={24}>
                            <Title className="text-cyan" level={3}>Haciendas</Title>
                        </Col>
                        <Col span={24}>
                            {
                                this.state.haciendas.length < 1 ? (
                                    <Empty description="Sin Haciendas" />
                                ) : (
                                    <Carousel responsive={this.responsive}>
                                        {
                                            this.state.haciendas.map(hacienda => <CardHacienda
                                                item={hacienda}
                                                color={hacienda.color}
                                                onClick={() => this.setState({ hacienda, ubicacion: false, terminos: false })}
                                            />)
                                        }
                                    </Carousel>
                                )
                            }
                        </Col>
                        <Col span={24}>
                            <Row align="middle">
                                <Col xs={24}>
                                    <Card className="card-zeus card-hacienda-compra">
                                        <Row>
                                            {(has_imagenes || has_mapa) && <Col span={8} style={{ position: "relative" }}>
                                                {
                                                    (has_imagenes && has_mapa) && <Menu

                                                        mode="horizontal"
                                                        className="menu-options">
                                                        <Menu.Item onClick={() => this.setState({ preview: "imagenes" })} style={{ padding: '2px 15px', lineHeight: 1.5 }}>Imagenes</Menu.Item>
                                                        <Menu.Item onClick={() => this.setState({ preview: "mapa" })} style={{ padding: '2px 15px', lineHeight: 1.5 }}>Mapa</Menu.Item>
                                                    </Menu>
                                                }
                                                {
                                                    (this.state.preview == "imagenes") && <Gallery
                                                        images={hacienda.imagenes}
                                                    />
                                                }
                                                {(this.state.preview == "mapa") && <Mapa value={this.state.hacienda?.poligono} />}
                                            </Col>}

                                            <Col span={has_imagenes || has_mapa ? 16 : 24} className="hacienda-content">
                                                <Row>
                                                    <Col flex="auto">
                                                        <Text style={{ fontSize: '28px' }}>Hacienda {hacienda.nombre}</Text>
                                                    </Col>
                                                </Row>
                                                <Row className="row-divider width-100" justify="space-between">
                                                    <EdadCounter span={12} fecha_creacion={hacienda.fecha_creacion} />
                                                    <Col span={12} className="center">
                                                        <IconPlace /><Text className="text-cyan">Hectáreas:</Text>&nbsp;<Text>{hacienda.hectareas?.toMoney && hacienda.hectareas?.toMoney(true)}</Text>
                                                    </Col>
                                                </Row>
                                                <Row className="row-data ">
                                                    <Col xs={24} md={16} className="flex-left ">
                                                        <IconAgabeColor /><Text className="text-cyan">Precio por Planta:</Text>
                                                    </Col>
                                                    <Col xs={24} md={8} align="end">
                                                        <Paragraph>$ {hacienda.precio?.toMoney && hacienda.precio?.toMoney(true)} MXN</Paragraph>
                                                        <Paragraph>$ {hacienda.precio_dolar?.toMoney && hacienda.precio_dolar?.toMoney(true)} USD</Paragraph>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={22}>
                                                        <Row justify="end">
                                                            {/* {console.log()} */}
                                                            <Col style={{ marginLeft: 16 }}>
                                                                <Button
                                                                    //   
                                                                    className={'btn-green ' + ((this.state.hacienda.disponible <= 0 || this.state.hacienda.estatus == 3) ? " disabled" : "")}
                                                                    disabled={this.state.haciendas.length < 1 || this.state.hacienda.disponible <= 0}
                                                                    onClick={() => this.setState({ modalHacienda: true })}>
                                                                    Comprar
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Title className="text-cyan" level={3}>Disponibles de otros Clientes</Title>
                        </Col>
                        <Col span={24}>
                            <Row align="middle" style={{ width: "100%" }}>
                                <ListaReventas
                                    hacienda_id={this.state.hacienda?._id}
                                    hacienda={this.state.hacienda}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <ModalHacienda
                        visible={this.state.modalHacienda}
                        id={this.state.hacienda._id}
                        hacienda={this.state.hacienda}
                        onClose={() => {
                            this.setState({ modalHacienda: false })
                            this.getHaciendas()
                        }}
                    />
                </Content>
            </Spin>
        )
    }
}