import React, { Component, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';

import io from "socket.io-client";

import axios from "axios"
//Componentes
import Dashboard from '../Components/Admin/Dashboard';
import DashboardPos from '../Components/Admin/POS/DashboardPos';
import Header from '../Components/Nav/Header'
import SidebarAdmin from '../Components/Nav/SidebarAdmin'

//Routes
import RouterUsuarios from './Admin/RouterUsuarios'
import RouterHaciendas from './Admin/RouterHaciendas'
import RouterInversiones from './Admin/RouterInversiones';
import RouterTransacciones from './Admin/RouterTransacciones'
import RouterProductos from './Admin/RouterProductos';
import RouterTamanos from './Admin/RouterTamanos';
import RouterCategorias from './Admin/RouterCategorias';
import RouterVentas from './Admin/RouterVentas'
import RouterPOS from './Admin/RouterPOS'
import RouterCRM from './Admin/RouterCRM'
import RouterEmpleados from './Admin/RouterEmpleados'
import RouterRoles from './Admin/RouterRoles'
import RouterAuthorize from './Admin/RouterAuthorize';
import RouterNominas from './Admin/RouterNominas'
import RouterReventas from './Admin/RouterReventas'

import Cotizador from "../Components/Admin/Cotizador/Cotizador"
import Theme from '../Components/ThemeEdit/index'

import ISYWidget from "iseeyoutech-widget-web/dist/iseeyoutech-widget-web.es.js";
import "iseeyoutech-widget-web/dist/style.css";

import '../Styles/Theme/Admin/antd-zeus-admin-theme.css'
import '../Styles/Global/admin.css'

import User from '../Hooks/Logged';
import Socket from '../Hooks/Socket';

// export const socket = io.connect(process.env.REACT_APP_WEB_SERVICE);

const { Content } = Layout;

export default class AdminRoutes extends Component {

	static contextType = User


	constructor(props) {
		super(props)
		this.state = {
			search: null,
			showSearch: true,
			clear: true,
			socket: this.getSocket()
		}
	}

	componentDidMount() {
		// console.log("user", user)
		//Importamos  los estilos de ANTD
		// require('../Styles/Theme/Admin/antd-zeus-admin-theme.css')
		// this.updateToken = axios.interceptors.response.use((response) => {
		// 	if (response?.headers?.authorization)
		// 		this.setState({ token: this.getSocket() })
		// 	return response;
		// }, function (error) {
		// 	return Promise.reject(error)
		// });
	}

	componentWillUnmount() {
		axios.interceptors.request.eject(this.updateToken)
		// .
	}

	getSocket = () => {
		try {

			this.state.socket?.disconnected()
		} catch (error) {
			console.log("e", error)
		}

		let socket = io.connect(process.env.REACT_APP_WEB_SERVICE, {
			extraHeaders: {
				"Authorization": sessionStorage.getItem('token')
			},

		})
		socket.io.on("open", () => {
			socket.io.engine.transport.on("pollComplete", () => {
				socket.io.opts.extraHeaders = {
					"Authorization": sessionStorage.getItem('token')
				}
			});
		})
		return socket
	}

	setFilterSearch = (e) => {
		this.setState({ search: e })
	}

	clearSearch = () => {
		if (this.state.clear) {
			this.setState({ clear: false })
		}
		else {
			this.setState({ clear: true })
		}
	}

	updateFilterSearch = (ShowFilter) => {
		if (ShowFilter === undefined) { ShowFilter = false }
		this.setState({
			showSearch: ShowFilter,
		})
	};

	render() {
		return (
			<Socket.Provider value={this.state.socket}>
				<Layout className="layout-main">
					<Header showSearch={this.state.showSearch} clear={this.state.clear} setFilterSearch={(e) => this.setFilterSearch(e)} />
					<Layout>
						<SidebarAdmin />

						<Content style={{ minHeight: '100vh' }}>
							<Routes>
								<Route
									path="dashboard"
									element={<Dashboard updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>
								<Route
									path="dashboard/pos"
									element={<DashboardPos updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>
								<Route
									path="usuarios/*"
									element={<RouterUsuarios updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="inversiones/*"
									element={<RouterInversiones updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="crm/*"
									element={<RouterCRM updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="productos/*"
									element={<RouterProductos updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="tamanos/*"
									element={<RouterTamanos updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="categorias/*"
									element={<RouterCategorias updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="haciendas/*"
									element={<RouterHaciendas updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="pos/*"
									element={<RouterPOS updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="nominas/*"
									element={<RouterNominas updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>
								<Route
									path="roles/*"
									element={<RouterRoles search={this.state.search} clearSearch={this.clearSearch} />}
								/>
								<Route
									path="authorize/*"
									element={<RouterAuthorize search={this.state.search} clearSearch={this.clearSearch} />}
								/>
								<Route
									path="empleados/*"
									element={<RouterEmpleados updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="ventas/*"
									element={<RouterVentas updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="transacciones/*"
									element={<RouterTransacciones updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="cotizador/*"
									element={<Cotizador updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

								<Route
									path="nominas/*"
									element={<RouterNominas updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>
								<Route
									path="roles/*"
									element={<RouterRoles search={this.state.search} clearSearch={this.clearSearch} />}
								/>
								<Route
									path="reventas/*"
									element={<RouterReventas search={this.state.search} clearSearch={this.clearSearch} />}
								/>
								<Route
									path="theme/*"
									element={<Theme updateFilterSearch={this.updateFilterSearch} clearSearch={this.clearSearch} search={this.state.search} />}
								/>

							</Routes>
							<ISYWidget
								nombre={(this.context?.nombres || this.context?.nombre) + " " + this.context?.apellido_paterno + " " + this.context?.apellido_materno}
								email={this.context?.email}
								proyectoId="632e03ceb3928934bc432c5a"
								URL="https://ws.admin.iseeyoutech.com"
							/>
							{/* <TicketWidget
								name={}
								email={}
								project="Zeus Agave"
							/> */}
						</Content>
					</Layout>
				</Layout>
			</Socket.Provider>
		)
	}
}