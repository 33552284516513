
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Typography, Button, Space, Pagination, Popconfirm, List, Card, message, } from "antd";
import { IconBtnDelete, IconEye } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/FloatingButton/FloatingButton";
import moment from 'moment';
import DrawerReporte from './DrawerReporte';


const axios = require('axios')

const { Content } = Layout;
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class Reportes
 * @extends {React.Component}
 */
export default class Reportes extends React.Component {


    /**
     *Creates an instance of Reportes.
     * @param {*} props
     * @memberof Reportes
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            currentPage: 1,
            itemCount: 0,
            limit: 10,


            //filtros
            filtroSearch: '',
            searching: true,


            drawer_productos_visible: false,
        }
    }

    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getReportes()
    }

    /**
     * @method getReportes
     * @description Solicita un listado de reportes
     */
    getReportes = (page = this.state.currentPage, limit = this.state.limit) => {
        let search = this.props.search;
        axios.get('/reportes', {
            page: page,
            limit,
            search
        }).then(res => {
            
            const data = res.data.data

            this.setState({
                data: data.itemsList,
                currentPage: data.paginator.currentPage,
                itemCount: data.paginator.itemCount,
                perPage: data.paginator.perPage,
                pageCount: data.paginator.pageCount,

                filtroSearch: search,
                searching: true,
                loading: false,
            })

        }).catch(error => {
            message.error('Error al traer el reportes')
            
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof Reportes
     * @method deleteReporte
     * @description Solicita eliminar un reporte
     * @param {string} id del reporte
     * 
     */
    deleteReporte = (id) => {
        axios.delete('reportes/delete', {
            params: {
                id
            }
        })
            .then(() => {
                message.success("¡Se ha eliminado el reporte!")
                this.getReportes()
            })
            .catch((e) => {
                message.error('No se pudo eliminar el reporte')
                
            })
    }

    /**
     *
     *
     * @memberof Reportes
     * @method addReporte
     * @description Solicita crear un nuevo reporte
     */
    addReporte = () => {
        this.setState({ loading: true }, () => {
            axios.post('/reportes/add')
                .then(() => {
                    message.success("¡Se ha creado correctamente el reporte!")
                    this.getReportes()
                })
                .catch((e) => {
                    message.error('No se pudo crear el reporte')
                    
                })
                .finally(() => this.setState({
                    loading: false
                }))
        })
    }

    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Reportes de Productos"
                    />
                    <Content className="admin-content content-bg pd-1 ">
                        <List
                            className="component-list "
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.data}
                            header={<Row className="header-list width-100 pl-1 pr-1 ">
                                <Col xs={24} md={7} xl={7} className=" center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={24} md={12} xl={12} className="center">
                                    <Text strong>Productos</Text>
                                </Col>
                                <Col xs={24} md={5} xl={5} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>
                            }
                            pagination={{
                                current:this.state.currentPage,
                                total:this.state.itemCount,
                                pageSize:this.state.limit,
                                onChange:(page, limit) => this.getReportes(page, limit),
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left"

                            }}
                            renderItem={item => (
                                <List.Item className="component-list-item ">
                                    <Card className="card-list">
                                        <Row className="width-100">
                                            <Col xs={24} md={7} xl={7} className=" center">
                                                <Text strong>{moment(item.fecha).format('DD-MM-YYYY HH:mm')}</Text>
                                            </Col>
                                            <Col xs={24} md={12} xl={12} className="center">
                                                <Text strong> {item.reportes_producto_ids.length} </Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={5} className="center">
                                                <Space direction="horizontal">
                                                    <Button
                                                        icon={<IconEye />}
                                                        type="primary"
                                                        title={"Ver Detalles"}
                                                        onClick={() => this.setState({ drawer_productos_visible: true, reporte_id: item._id })}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este reporte?"
                                                        onConfirm={() => this.deleteReporte(item._id)}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<IconBtnDelete />} title="Eliminar" />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                        />
                        <Popconfirm
                            placement="topRight"
                            title="¿Deseas generar el reporte del día?"
                            onConfirm={() => this.addReporte()}
                            okText="Si"
                            cancelText="No"
                        >
                            <FloatingButton title="Generar reporte diario" />
                        </Popconfirm>

                    </Content>
                </Spin>


                <DrawerReporte
                    reporte_id={this.state.reporte_id}
                    visible={this.state.drawer_productos_visible}
                    onSave={() => {
                        this.setState({ drawer_productos_visible: false, reporte_id: null })
                        this.getReportes()
                    }}
                    onClose={() => this.setState({ drawer_productos_visible: false, reporte_id: null })}
                />
            </>
        )
    }
}