import React, { Component } from "react";
import { FilePdfOutlined, SwapOutlined } from "@ant-design/icons";
import { Row, Col, Space, Button, List, Typography, Card, message, Statistic, Popconfirm, Popover, Tag, Tooltip } from "antd";

//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconBtnDelete, IconBtnEdit, IconEye, IconMail, IconMailOpen } from '../../../Widgets/Iconos';

const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class EstadosCuenta
 * @extends {Component}
 * @description Vista del listado de estados de cuenta
 */
class EstadosCuenta extends Component {

    constructor(props) {
        super(props)
        this.state = {
            estados: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                cliente_id: this.props.cliente_id
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEstadosCuenta()
    }

    /**
    * @memberof EstadosCuenta
    * @description Obtiene todas todas las EstadosCuenta relacionadas al cliente y a sus inversiones
    */
    getEstadosCuenta = ({
        page = this.state.estados.page,
        limit = this.state.estados.limit,
    } = this.state.estados) => {

        this.setState({loading: true})
        axios.get('/estados-cuentas', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id,
            }
        })
            .then(({ data }) => {
                this.setState({
                    estados:{
                        ...this.state.estados,
                        data: data.data
                    }
                })
            })
            .catch(err => {
                message.error('No se pudieron cargar los estado de cuenta.')
            })
            .finally(() => this.setState({ loading: false }))

    }

    /**
     *
     * @memberof Compras
     * 
     * @method getPDF
     * @description Generamos el PDF del comprobante de pago
     */
    getPDF = (estado_cuenta_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/estados-cuenta');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('estado_cuenta_id', estado_cuenta_id)
        window.open(urlPDF.href, '_blank');
    }

     /**
     *
     *
     * @memberof MatrizCostos
     *
     * @method sendPDF
     * @description Enviamos la cotizacion al cliente
     */
    sendPDF = (estado_cuenta_id) => {
        this.setState({ loading: true });
        return axios
            .get("/estados-cuenta", {
                params: {
                    estado_cuenta_id: estado_cuenta_id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Estado de Cuenta Enviado");
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 403)
                    message.error(error.response.data.message)
                else
                    message.error("Error al enviar el recibo");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };


    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list "
                    size="large"
                    itemLayout="horizontal"
                    dataSource={this.state.estados.data}
                    locale={{ emptyText: "Sin Estados de Cuenta" }}
                    header={<Row className=" width-100 pl-1 pr-1 ">

                        <Col xs={6} xl={4} className="center">
                            Fecha de Envio
                        </Col>

                        <Col xs={6} xl={4} className="center">
                            Inversiones
                        </Col>
                        <Col xs={6} xl={4} className="center">
                            Subtotal
                        </Col>
                        <Col xs={6} xl={4} className="center">
                        </Col>
                        <Col className="center" xs={6} xl={4}>
                        </Col>
                        <Col xs={24} md={4}></Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
                                <Row className="width-100 " gutter={[0, 8]}>
                                    <Col className="center" xs={6} xl={4}>
                                        <Text>{(moment(item.fecha_envio).format("MMMM YYYY")).toUpperCase()}</Text>
                                    </Col>
                                    <Col className="center" xs={6} xl={4}>
                                        <Text>{item.inversiones.length}</Text>
                                    </Col>
                                    <Col className="center" xs={6} xl={4}>
                                        <Text>$ { item.subtotal.toMoney(true) } MXN</Text>
                                    </Col>
                                    <Col className="center" xs={6} xl={4}>
                                        
                                    </Col>
                                    <Col className="center" xs={6} xl={4}>
                                        
                                    </Col>
                                    <Col className="flex-right" xs={4}>
                                        <Space direction="horizontal">

                                        {
                                                item.fecha_envio ? (
                                                    <Tooltip
                                                        title={`Enviado hace ${moment(item.fecha_envio).toNow(true)}`}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            className='ant-btn-primary-purple'
                                                            icon={<IconMailOpen/>}
                                                            onClick={()=>this.sendPDF(item._id)}
                                                        />
                                                    </Tooltip> 
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        className='ant-btn-primary-purple'
                                                        icon={<IconMail />}
                                                        onClick={()=>this.sendPDF(item._id)}
                                                    />
                                                )
                                            }
                                            <Button
                                                type="primary"
                                                className='ant-btn-primary-blue'
                                                icon={<FilePdfOutlined />}
                                                onClick={()=>this.getPDF(item._id)}
                                            />

                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar este Estado de Cuenta?"
                                                onConfirm={() => axios.delete('/estados-cuenta/delete', { params: { estado_cuenta_id: item._id } }).then((response) => {
                                                    message.success(response?.data?.message)
                                                    this.getEstadosCuenta()
                                                })
                                                    .catch((error) => {
                                                        message.error(error?.response?.data?.message);
                                                        this.getEstadosCuenta();
                                                    })
                                                }
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button type="primary" danger className='btn-delete' title="Eliminar" icon={<IconBtnDelete />} />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}

                />
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<EstadosCuenta {...props} />)
}