import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, List, message, Tag, Spin, Divider, DatePicker, Upload, Space, Card, Popconfirm } from 'antd';
import { PlusOutlined, UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

//componentes
import ChatCliente from './ChatCliente'
import ModalInversion from '../Inversiones/ModalInversion'
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import { SelectPais, SelectEstado, SelectUsuario } from '../../Widgets/Inputs/Inputs'
import PhoneInput from '../../Widgets/Inputs/PhoneInput'
import { renderEstatusCliente } from '../../Utils'
import EmailOnBlur from '../../Widgets/Inputs/EmailOnBlur'
//css
import '../../../Styles/Modules/Admin/CRM.scss'
import ModalBeneficiario from "./ModalBeneficiario";

const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment')


/**
 * @class ModalClienteDetalle
 * @extends {Component}
 * 
 *  @description Corresponde
 */
class ModalClienteDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cliente: {},
            beneficiarios: [{},{}],
            cliente_id: this.props.cliente_id,
            pais_id: null,
        }
    }

    formRef = React.createRef()

    /**
     * @methodOf ModalClienteDetalle
     * @function componentDidMount
     * @description obtiene la informacion inicial
     */
    componentDidMount() {
        if (this.props.cliente_id) {
            this.getCliente()
        }
    }

    /**
     * @methodOf ModalClienteDetalle
     * @function getCliente
     * @description obtiene la informacion del cliente
     */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente', {
            params: {
                cliente_id: this.props.cliente_id
            }
        }).then(response => {

            let cliente = response.data.data
            let beneficiarios = response.data.beneficiarios

            console.log("cliente", beneficiarios)

            this.setState({
                cliente: cliente,
                pais_id: cliente.pais_id,
                beneficiarios: beneficiarios
            })

            if (cliente.evidencias) {
                cliente.evidencias = cliente.evidencias?.map((archivo, index) => ({
                    uid: index,
                    name: archivo,
                    status: 'done',
                    url: `${axios.defaults.baseURL}/upload/${archivo}`,
                }))
            }

            this.formRef.current.setFieldsValue({
                ...cliente,
                fecha_nacimiento: moment(cliente.fecha_nacimiento),
                vendedor_id: cliente.vendedor_id?._id ? {
                    label: `${cliente.vendedor_id?.nombre} ${cliente.vendedor_id?.apellido_paterno} ${cliente.vendedor_id?.apellido_materno ?? ''}`,
                    value: cliente.vendedor_id?._id,
                    key: cliente.vendedor_id?._id,
                } : null,
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtner la información del cliente')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
     * @methodOf ModalClienteDetalle
     * @function updateCliente
     * @description obtiene la informacion del cliente
     */
    updateCliente = (values) => {

        const v = { ...values }

        if (Array.isArray(v.evidencias) && v.evidencias?.length > 0) {
            v.evidencias = v.evidencias?.map(archivo => archivo.name)
        } else {
            v.evidencias = []
        }

        axios.put('/clientes/update', {
            cliente_id: this.props.cliente_id,
            ...v

        }).then(response => {
            console.log("actualizado con exito");
        }).catch(error => {
            console.log("error", error);
            message.error('Error al actualizar la información del cliente')
        })
    }

    /**
    * @memberOf ModalClienteDetalle
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = values => {

        const v = { ...values }

        if (Array.isArray(v.evidencias) && v.evidencias?.length > 0) {
            v.evidencias = v.evidencias?.map(archivo => archivo.name)
        } else {
            v.evidencias = []
        }

        if (this.props.cliente_id) {

        } else {
            this.addCliente(v)
        }
    }

    /**
    * @memberOf ModalClienteDetalle
    * @method addCliente
    * @param {object} cliente El objeto cliente
    * @description Crea un nuevo registro de cliente
    */
    addCliente = cliente => {
        axios.post('/clientes/add', cliente)
            .then(response => {
                message.success('Se ha guardado exitosamente.')
                this.props.onCancel()
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {object} e - Obtiene los datos del evento
     * @memberof FormCliente
     * @method normFile
     * @description Maneja el evento de carga de archivos
     */
    normFile = (e) => {
        const { file, fileList } = e;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.setState({ update: !this.state.update, loading: true })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de archivos, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loading: false
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response?.filename !== undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };

    /**
     *
     *
     * @param {string} filename - Nombre del documento
     * @memberof FormCliente
     * @method removeFile
     * @description Elimina el documento en base a sun nombre
     */
    removeFile = (filename) => {
        axios.post("/upload/delete", {
            filename
        })
            .then(res => {

            })
            .catch(res => {

            })
    };


    render() {

        const { cliente } = this.state;

        return (
            <Row>
                <Col span={this.props.cliente_id ? 12 : 24} className="modal-col-content">

                    <Form
                        layout="vertical"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        onValuesChange={(values, allvalues) => {
                            if (this.props.cliente_id) {
                                this.updateCliente(values);
                            }
                        }}

                    >
                        <Row className="mb-1" align="end">
                            {this.props.cliente_id ? <Col span={7} className="center">
                                {renderEstatusCliente({ estatus: cliente.estatus })}
                            </Col> : null}
                            <Col span={17}>
                                <Form.Item
                                    label=""
                                    name="vendedor_id"
                                >
                                    <SelectUsuario
                                        placeholder="Vendedor"
                                        className="width-100"
                                        bordered={false}
                                        params={{
                                            clientes: false
                                        }}
                                    />

                                </Form.Item>
                            </Col>
                        </Row>

                        <Title level={5} className="text-title"> Información del Cliente </Title>

                        <Divider />

                        <Row justify="center" gutter={[8, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    label=""
                                    name="nombre"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese el nombre',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Nombre"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="apellido_paterno"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese el apellido paterno',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Apellido Paterno"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="apellido_materno"
                                >
                                    <Input
                                        placeholder="Apellido Materno"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Email no valido',
                                        },

                                    ]}
                                >
                                    <EmailOnBlur
                                        placeholder="Correo Electrónico"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="telefono"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingrese el teléfono',
                                        },
                                    ]}
                                >
                                    <PhoneInput bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="fecha_nacimiento"
                                >
                                    <DatePicker
                                        placeholder="Fecha de Nacimiento"
                                        bordered={false}
                                        className="width-100"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="fuente"
                                    rules={[
                                        { required: true, message: "Seleccione la fuente" }
                                    ]}>
                                    <Select placeholder={"Seleccione la fuente"} bordered={false}>
                                        <Option value={1}>Sistema</Option>
                                        <Option value={2}>Facebook</Option>
                                        <Option value={3}>Instagram</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Title level={5} className="text-title"> Dirección </Title>

                            <Divider />

                            <Col span={24}>
                                <Form.Item
                                    label=""
                                    name="calles"
                                >
                                    <Input
                                        placeholder="Dirección"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="numero"
                                >
                                    <Input
                                        placeholder="Número"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="codigo_postal"
                                >
                                    <Input
                                        placeholder="Código Postal"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="colonia"
                                >
                                    <Input
                                        placeholder="Colonia"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="ciudad"
                                >
                                    <Input
                                        placeholder="Ciudad"
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="pais_id"
                                >
                                    <SelectPais
                                        onSelect={(pais_id) => this.setState({ pais_id })}
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label=""
                                    name="estado_id"
                                >
                                    <SelectEstado
                                        pais_id={this.state.pais_id}
                                        bordered={false}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Title level={5} className="text-title"> Beneficiarios </Title>
                        <Divider />

                        <Row justify="center">
                            <Col span={24}>
                                <List
                                    loading={this.state.loading}
                                    className="width-100"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Sin Beneficiarios " }}
                                    dataSource={this.state.beneficiarios}
                                    header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>
                                        <Col xs={24} lg={20}>
                                            <Text strong>Beneficiario</Text>
                                        </Col>
                                        
                                    </Row>}

                                    renderItem={item => (
                                        <List.Item className="width-100 p-0">
                                            <Card className="width-100">
                                                <Row className="width-100 " align={"middle"} gutter={[16, 16]}>

                                                    <Col xs={24} lg={20}>
                                                        <Row>
                                                        <Text>{item.nombre}</Text>
                                                        </Row>
                                                        <Row>
                                                        <small><Text italic>{item.email}</Text></small>
                                                        </Row>
                                                        <Row>
                                                        <small><Text italic>{item.telefono}</Text></small>
                                                        </Row>
                                                        
                                                    </Col>
                                                    <Col xs={24} lg={4} align="right">
                                                        <Space size="small" direction="horizontal">
                                                            <Button
                                                                type="primary"
                                                                
                                                                icon={<EditOutlined />}
                                                                title="Editar"
                                                                onClick={() => this.setState({ modalBeneficiario: true, beneficiario_id: item._id })}
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                
                                                                title="¿Deseas eliminar este beneficiarios?"
                                                                onConfirm={() => axios.delete('/beneficiario/delete',
                                                                    { params: { beneficiario_id: item._id } }).then(() => {
                                                                        this.getCliente()
                                                                        message.success('Beneficiario eliminado')
                                                                    }).catch(error => {
                                                                        
                                                                            message.error('Error al eliminar el beneficiario')
                                                                        
                                                                    })}
                                                                okText="Si"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    
                                                                    type="primary"
                                                                    icon={<DeleteOutlined />}
                                                                    title="Eliminar"
                                                                    danger
                                                                />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                                <Button className="w-100" onClick={() => this.setState({ modalBeneficiario: true })}>
                                    Agregar Beneficiario +
                                </Button>
                            </Col>
                        </Row>

                        <Title level={5} className="text-title"> Evidencias </Title>
                        <Divider />

                        <Row justify="center">
                            <Col span={24}>
                                <Form.Item
                                    label="Evidencias"
                                    name="evidencias"
                                    getValueFromEvent={this.normFile}

                                    valuePropName="fileList"
                                >
                                    <Upload
                                        action={axios.defaults.baseURL + "/upload/add"}
                                        multiple={true}
                                        className="upload-large"
                                    >
                                        <Button>
                                            <UploadOutlined /> Subir Evidencias
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>


                            {this.props.cliente_id ? <Col span={24} className="flex-right">
                                <Button onClick={() => this.setState({ modalInversión: true })}>
                                    Agregar Inversión
                                </Button>

                            </Col> : <Col span={24} className="flex-right">
                                <Space>
                                    <Button type="secondary">
                                        Cancelar
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Guardar
                                    </Button>
                                </Space>
                            </Col>}
                        </Row>

                    </Form>

                </Col>

                {this.props.cliente_id ? <Col span={12} className="">
                    <ChatCliente cliente_id={this.props.cliente_id} />
                </Col> : null}
                <ModalBeneficiario
                    visible={this.state.modalBeneficiario}
                    beneficiario_id={this.state.beneficiario_id}
                    cliente_id={this.props.cliente_id}
                    onClose={() => { this.setState({ modalBeneficiario: false, beneficiario_id: null }); this.getCliente() }}
                />
                <ModalInversion
                    visible={this.state.modalInversión}
                    cliente={this.state.cliente}
                    onClose={(status) => {
                        this.setState({ modalInversión: false })

                        if (status == true)
                            this.props.navigate(`/admin/crm/clientes/detalle/${this.state.cliente?._id}`)

                    }}
                />
            </Row>
        )
    }
}



export default function (props) {

    const { visible, onCancel } = props

    const navigate = useNavigate()
    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={null}
        footer={null}
        closable={false}
        className="modal-detalle-chat"
        destroyOnClose={true}
        zIndex={1000}
        width={props.cliente_id ? 1200 : 600}

    >
        <ModalClienteDetalle {...props} navigate={navigate} />
    </Modal>


}
