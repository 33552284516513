import React, { Component } from 'react';
import {
    Row, Col, Typography, Button, Popconfirm, Spin,
    PageHeader, Layout, Space, message, List, Card, Form, Dropdown, Tag, Radio, Menu, Pagination, Collapse, Select, Checkbox, Tooltip, Input
} from 'antd'
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, EyeOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons';

import useSearchParams from '../../../Hooks/SearchParams';
import User from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';


//componentes
import ModalCliente from './ModalCliente';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton'
import { IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos'

import ModalDetalle from "./ModalClienteDetalle"

import '../../../Styles/Modules/Admin/clientes.scss'
import moment from 'moment';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import SelectVendedor from './Clientes/SelectVendedor';

const { Content } = Layout;
const { Text } = Typography
const { Option } = Select
/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class Clientes extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        let { skip, limit, filters = [], search } = this.props.params

        try {
            filters = JSON.parse(filters)
        } catch (error) {
            console.log("a", error)
        }

        this.state = {
            loading: false,

            clientes: {
                data: {
                    0: [],
                    1: [],
                    2: [],
                    3: [],
                    4: [],
                    5: [],
                },
                skip: skip ?? 0,
                limit: limit ?? 50,
                filters: filters ?? [],
                total: 0,
            },
            vendedores: {
                data: [],
                page: 1,
                limit: 10,
                total: 0
            },
            sort: 1,
            panels: [0, 1, 2, 3, 4],
            seleccionados: {},
            clearCheckbox: false,
            modalCliente: false,
            search: this.props.search || search,
            visibleFilters: false,
            optionsVisible: false,
            first: true
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientes({ filters: this.state.filters });
        this.getVendedores()

        const self = this
        window.onscroll = function () {
            if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 50) && !self.state.loading && self.state.clientes.skip < self.state.clientes.total) {
                self.getClientes({ skip: (parseInt(self.state.clientes.skip) + parseInt(self.state.clientes.limit)) })
            }
        }
    }

    componentWillUnmount() {
        window.onscroll = null
    }


    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
             this.getClientes({ search: this.props.search, skip: 0 })
        }
    }

    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getClientes = ({
        skip = this.state.clientes.skip,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
        filters = this.state.clientes.filters,
        panels = this.state.panels,
        sort = this.state.sort
    } = this.state.clientes, { } = this.state
    ) => {
        const params = { limit, skip }
        // if (sort && Object.values(sort).length > 0 ) params.sort = JSON.stringify(sort)
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        this.props.setParams(params)
        let clearCheckbox = (skip === 0)

        this.setState({ loading: true, panels, clearCheckbox, sort })
        axios.get('/clientes', {
            params: {
                skip: this.state.first ? 0 : skip,
                limit: (this.state.first && skip > 0) ? skip : limit,
                panels,
                search: this.props.search,
                filters,
                group: "estatus",
                sort
            }
        })
            .then(({ data }) => {
                this.setState({
                    clientes: {
                        ...data,
                        data: {
                            0: (skip == 0) ? (data.data[0] ?? []) : this.state.clientes.data[0].concat(data.data[0] ?? []),
                            1: (skip == 0) ? (data.data[1] ?? []) : this.state.clientes.data[1].concat(data.data[1] ?? []),
                            2: (skip == 0) ? (data.data[2] ?? []) : this.state.clientes.data[2].concat(data.data[2] ?? []),
                            3: (skip == 0) ? (data.data[3] ?? []) : this.state.clientes.data[3].concat(data.data[3] ?? []),
                            4: (skip == 0) ? (data.data[4] ?? []) : this.state.clientes.data[4].concat(data.data[4] ?? []),
                        },
                        skip,
                        limit,
                        filters: filters
                    },
                    first: false
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }



    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.clientes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })
            this.getClientes({ filters, skip: 0 })
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }


    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters } = this.state.clientes) => {
        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.filter?.nombre || filtro?.filter?.label || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    }

    getVendedores = ({ page, limit, search } = this.state.vendedores, { vendedores } = this.state) => {

        vendedores.loading = true;
        vendedores.page = page;
        vendedores.limit = limit;

        this.setState({ vendedores })

        axios.get('/usuarios', {
            params: {
                page, limit, search,
                clientes: false
            }
        })
            .then(response => {
                vendedores.data = (page === 1) ? response.data.data.itemsList : [...vendedores.data, ...response.data.data.itemsList];
                vendedores.total = response.data.data.itemCount
                vendedores.pages = response.data.data.pageCount
                vendedores.loading = false;

                this.setState({ vendedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.vendedores.loading = false
                    return state
                })
            })
    }

    asignarVendedor = (vendedor_id) => {
        this.setState({ loading: true })

        axios.post('/clientes/asignar-vendedor', {
            vendedor_id, ids: Object.keys(this.state.seleccionados)
        }).then(success => {
            message.success("Vendedor asignado")
            this.getClientes({skip: 0})
            this.getVendedores({search: undefined})
        }).catch(error => {
            message.error("Error al asignar vendedor")
        })
    }

    render() {

        const { clientes, loading } = this.state

        let estatus = [
            {
                _id: 0,
                name: 'cancelados',
                label: "Desisitío / Perdido"
            },
            {
                _id: 1,
                name: 'registrados',
                label: "Registrado"
            },
            {
                _id: 2,
                name: 'contactado',
                label: "Contactado"
            },
            {
                _id: 3,
                name: 'comprado',
                label: "Ha invertido"
            },
            {
                _id: 4,
                name: 'pagado',
                label: "Pagado"
            }
        ]


        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Row className="width-100" justify="space-between" align="middle">
                        <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                            <span className="ant-page-header-heading-title" >
                                Clientes <small>{this.state.clientes.total} Clientes<i></i> </small>
                            </span>
                        </Col>
                        <Col span={24}>
                            {this.renderFiltros()}
                        </Col>
                    </Row>}
                    extra={<Space>
                        <Link to={`/admin/crm/clientes/listado`} >
                            <Button > Ver Listado </Button>
                        </Link>
                        <Button
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
                    </Space>}
                />
                {/* <Select style={{ width: 240, position: "sticky", top: 30, }} ><Select.Option>sdfasdf</Select.Option></Select> */}
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <Space size={16}>
                                <Select
                                    disabled={!this.props.asignarVendedor}
                                    style={{width: 200}}
                                    showSearch
                                    placeholder={"Asignar vendedor"}
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(value) => this.getVendedores({search: value})}
                                    onSelect={(value) => {
                                        if(Object.keys(this.state.seleccionados).length < 1){
                                            message.info("Necesitas seleccionar algunos clientes")
                                            this.getVendedores({search: undefined})
                                            return
                                        }

                                        this.asignarVendedor(value)
                                    }}
                                    
                                    notFoundContent={"No se encontro vendedor"}
                                >
                                    {
                                        this.state.vendedores.data.map(({ _id, nombre,nombres, apellido_paterno, apellido_materno }) => {
                                            return <Option key={_id} value={_id}>{`${nombre || nombres} ${apellido_paterno || ""} ${apellido_materno || ""}`}</Option>
                                        })
                                    }
                                </Select>
                                <Popconfirm
                                    placement="top"
                                    title="¿Deseas eliminar estos clientes?"
                                    onConfirm={() => {
                                        if(Object.keys(this.state.seleccionados).length < 1){
                                            message.info("Necesitas seleccionar algunos clientes")
                                            return
                                        }
                                        
                                        axios.delete('/clientes/delete-many', { params: { ids: Object.keys(this.state.seleccionados) } })
                                        .then((response) => {
                                            message.success(response?.data?.message)
                                        })
                                        .catch((error) => {
                                            message.error(error?.response?.data?.message);
                                        })
                                        .finally(() => {
                                            this.setState({seleccionados: []})
                                            this.getClientes({skip: 0})
                                        })
                                    }}
                                    okText="Si"
                                    cancelText="No"
                                    disabled={!this.props.eliminarClientes}
                                >
                                    <Tooltip
                                        title="Eliminar"
                                    >
                                        <Button
                                            disabled={!this.props.eliminarClientes}
                                            type="primary"
                                            size="large"
                                            icon={<DeleteOutlined/>}
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            </Space>
                        </Col>
                        <Col xs={24}>
                            <Collapse
                                bordered={false}
                                className="clientes-collapse w-100"
                                activeKey={this.state.panels}
                                onChange={e => this.getClientes({ skip: 0, panels: e })}
                            >
                                {estatus.map(({ _id, name, label }) => {
                                    return <Collapse.Panel
                                        key={_id}
                                        header={label}
                                        className={`clientes-tab tab-${name}`}
                                    >
                                        <List
                                            loading={clientes.loading}
                                            className="component-list"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: this.state.loading ? "Cargando" : "Sin Clientes" }}
                                            dataSource={this.state.clientes.data[_id] ?? []}
                                            renderItem={(cliente, index) => (
                                                <List.Item className="component-list-item" key={cliente._id}>
                                                    <Card className="card-list">
                                                        <Row align="middle">
                                                            <Col flex="none">
                                                                <Space size={8}>
                                                                    {
                                                                        !this.state.clearCheckbox && (
                                                                            <Checkbox
                                                                                onChange={(e) => {
                                                                                    if(e.target.checked){
                                                                                        this.state.seleccionados[cliente._id] = true
                                                                                    }else{
                                                                                        delete this.state.seleccionados[cliente._id]
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                    <Dropdown
                                                                        destroyPopupOnHide={false}
                                                                        overlay={<Menu>
                                                                            <Menu.Item onClick={() => this.props.updateEstatus(cliente._id, 0)}>
                                                                                <Space direction="horizontal" size={8}>
                                                                                    <div className={`clientes-circle small circle-1`} />
                                                                                    <Text>Desistió / Cancelado</Text>
                                                                                </Space>
                                                                            </Menu.Item>
                                                                            <Menu.Item onClick={() => this.props.updateEstatus(cliente._id, 1)}>
                                                                                <Space direction="horizontal" size={8}>
                                                                                    <div className={`clientes-circle small circle-2`} />
                                                                                    <Text>Registrado</Text>
                                                                                </Space>
                                                                            </Menu.Item>
                                                                            <Menu.Item onClick={() => this.props.updateEstatus(cliente._id, 2)}>
                                                                                <Space direction="horizontal" size={8}>
                                                                                    <div className={`clientes-circle small circle-3`} />
                                                                                    <Text>Contactado</Text>
                                                                                </Space>
                                                                            </Menu.Item>
                                                                            <Menu.Item onClick={() => this.props.updateEstatus(cliente._id, 3)}>
                                                                                <Space direction="horizontal" size={8}>
                                                                                    <div className={`clientes-circle small circle-4`} />
                                                                                    <Text>Ha invertido</Text>
                                                                                </Space>
                                                                            </Menu.Item>
                                                                            <Menu.Item onClick={() => this.props.updateEstatus(cliente._id, 4)}>
                                                                                <Space direction="horizontal" size={8}>
                                                                                    <div className={`clientes-circle small circle-5`} />
                                                                                    <Text>Pagado</Text>
                                                                                </Space>
                                                                            </Menu.Item>
                                                                        </Menu>}
                                                                        className="clientes-dropdown"
                                                                        trigger={['click']}
                                                                    >
                                                                        <div className={`clientes-circle`} ></div>
                                                                    </Dropdown>
                                                                </Space>
                                                            </Col>
                                                            <Col flex="auto">
                                                                <Row className="width-100 " gutter={[0, 12]}>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis strong>{cliente.nombre} {cliente.apellido_paterno} {cliente.apellido_materno}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        <Text ellipsis >{cliente.email}</Text>
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        <Text ellipsis >{cliente.telefono}</Text>
                                                                    </Col>
                                                                    <Col xs={5} className="center" >
                                                                        {
                                                                            !this.props.editarClientes ? (cliente.vendedor ? (
                                                                                <Space direction="horizontal" size={8}>
                                                                                    <CustomAvatar
                                                                                        image={`${axios.defaults.baseURL}/upload/${cliente.vendedor?.avatar}`}
                                                                                        name={`${cliente.vendedor?.nombre || ''} ${cliente.vendedor?.apellido_paterno || ''} ${cliente.vendedor?.apellido_materno || ''}`}
                                                                                    />
                                                                                    <Text ellipsis >{cliente.vendedor?.nombre}</Text>
                                                                                </Space>
                                                                            ) : (<Text ellipsis >Sin Vendedor Asignado</Text>)
                                                                            ) : (
                                                                                <SelectVendedor
                                                                                    item={cliente}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        <Text ellipsis >{moment(cliente.createdAt).format("DD/MM/YYYY")}</Text>
                                                                    </Col>
                                                                    <Col xs={3} className="center" >
                                                                        <Space wrap>
                                                                            <Link to={`/admin/crm/clientes/detalle/${cliente._id}`} >
                                                                                <Button type='primary' disabled={!this.props.verClienteDetalles} className='btn-primary' icon={<IconEye />} />
                                                                            </Link>
                                                                            <Button type='primary' disabled={!this.props.editarClientes} className='btn-secondary' icon={<IconBtnEdit />} onClick={() => this.setState({modalDetalle: true, cliente_id: cliente._id})} />
                                                                            <Popconfirm
                                                                                placement="topRight"
                                                                                title="¿Deseas eliminar este cliente?"
                                                                                onConfirm={() => axios.delete('/clientes/delete', { params: { cliente_id: cliente._id } })
                                                                                    .then((response) => {
                                                                                        message.success("Eliminado correctamente")
                                                                                        this.state.clientes.data[_id].splice((index), 1)
                                                                                        this.setState(state => {
                                                                                            return state
                                                                                        })
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        message.error(error?.response?.data?.message);
                                                                                    })
                                                                                }
                                                                                okText="Si"
                                                                                cancelText="No"
                                                                            >
                                                                                <Button type="primary" danger disabled={!this.props.eliminarClientes} className='btn-delete' title="Eliminar" icon={<DeleteOutlined />} />
                                                                            </Popconfirm>
                                                                        </Space>
                                                                    </Col>
                                                                </Row>   
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                        />
                                    </Collapse.Panel>
                                })}
                            </Collapse>
                        </Col>
                    </Row>
                </Content>

                {this.props.crearClientes && <FloatingButton title="Nuevo Registro" disabled={!this.props.crearClientes} onClick={() => this.setState({ modalDetalle: true, cliente_id: null })} />}
                
                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Clientes"
                    csv="clientes"
                    updateFilters={filters => this.getClientes({
                        skip: 0,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    estatus={{
                        type: 'list',
                        data: [{
                            _id: 0,
                            label: "Desisitío / Perdido"
                        }, {
                            _id: 1,
                            label: "Registrado"
                        }, {
                            _id: 2,
                            label: "Contactado"
                        }, {
                            _id: 3,
                            label: "Ha invertido"
                        }, {
                            _id: 4,
                            label: "Pagado"
                        }]
                    }}
                    haciendas={false}
                    cuentas={false}
                    clientes={false}
                    ordenes={false}
                    ordenes_compras={false}
                    area_rubro={false}
                    clasificadores={false}
                    razones_sociales={false}
                    negocios={false}
                    proveedores={false}
                    inversiones={false}
                    vendedores_transaccion={false}                    
                />

                <ModalCliente
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                    }}

                />

                <ModalDetalle
                    visible={this.state.modalDetalle}
                    cliente_id={this.state.cliente_id}
                    onCancel={() => {
                        this.setState({ modalDetalle: false, cliente_id: undefined })
                        this.getClientes()
                    }}
                />
            </>
        )
    }
}



export default function (props) {

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearClientes: ["clientes", "create"],
        editarClientes: ["clientes", "edit"],
        eliminarClientes: ["clientes", "delete"],
        verClienteDetalles: ["clientes", "detalles"],
        asignarVendedor: ["clientes", "assign-vendedor"]
    })

    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useSearchParams();


    return <Clientes {...props}
        user={user}
        tipo_acceso={user.rol_id?.tipo}
        {...permisos}
        navigate={navigate}
        location={location}
        params={params}
        setParams={setParams}
    />
}