import React, { Component } from "react";
import { FilePdfOutlined } from "@ant-design/icons";
import { Row, Col, Space, Button, List, Typography, Card, message, Statistic, Popconfirm, Popover } from "antd";

//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconBtnDelete, IconBtnEdit, IconEye, IconMail } from '../../../Widgets/Iconos';
import ModalTransaccionDetalle from "../../Transacciones/ModalTransaccionDetalle";
import ModalTransaccion from "../../Transacciones/ModalTransaccion";


const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class Compras
 * @extends {Component}
 * @description Vista del listado de compras realizadas por el cliente
 */
class Compras extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transacciones: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                cliente_id: this.props.cliente_id
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props.cliente_id){
            this.getTransacciones()
        }
        
    }

    /**
    * @memberof Compras
    * @description Obtiene todas todas las transacciones realizadas por el cliente relacionadas a POS
    * @param values pagina
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
    } = this.state.transacciones) => {

        this.setState({loading: true})
        axios.get('/transacciones', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id,
                pos: true,
                tipo: 1 //ingreso
            }
        })
        .then(({ data }) => {

            
            this.setState(state => {
                state.transacciones.total = data.data.itemCount
                state.transacciones.data = data.data.itemsList
                state.transacciones.page = data.data.pageCount
                state.transacciones.loading = false
                return state;
            })
        })
        .catch(err => {
            
            message.error('No se pudieron cargar las transacciones.')
        })
        .finally(() => this.setState({ loading: false }))

    }

    /**
     *
     * @memberof Compras
     * 
     * @method getPDF
     * @description Generamos el PDF del comprobante de pago
     */
    getPDF = (transaccion_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('transaccion_id', transaccion_id)
        window.open(urlPDF.href, '_blank');
    }

    /**
     *
     *
     * @memberof Compras
     *
     * @method sendPDF
     * @description Enviamos el comprobante de pago por email
     */
    sendPDF = (transaccion_id) => {
        this.setState({ loading: true });
        return axios
            .get("/transaccion/comprobante/pdf", {
                params: {
                    transaccion_id: transaccion_id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Comprobante Enviado");
                this.getInversiones();
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al enviar el comprobante");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };


    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list "
                    size="large"
                    itemLayout="horizontal"
                    dataSource={this.state.transacciones.data}
                    locale={{ emptyText: "Sin Compras" }}
                    pagination={{
                        current: this.state.transacciones.page,
                        pageSize: this.state.transacciones.limit,
                        total: this.state.transacciones.total,
                        position: 'bottom',
                        className: "flex-left ml-1",
                        onChange: (page) => this.getTransacciones({page}),
                        hideOnSinglePage: true
                    }}
                    header={<Row className=" width-100 pl-1 pr-1 ">
                        <Col  xs={6} className="center">
                            Fecha
                        </Col>
                        <Col xs={6} className="center">
                            Concepto
                        </Col>
                        <Col xs={6} className="center">
                            Monto
                        </Col>
                        <Col xs={6}></Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
                                <Row className="width-100 " gutter={[0, 8]}>
                                    <Col className="center" xs={6}>
                                        {moment(item.createdAt).format('DD-MM-YYYY')}
                                    </Col>
                                    <Col className="center" xs={6}>
                                    	{item.concepto}
                                    </Col>
                                    <Col className="center" xs={6}>
                                        $ {item.monto?.toMoney ? item.monto.toMoney(true) : 0} {item.moneda || "MXN"}
                                    </Col>
                                    <Col className="flex-right" xs={6}>
                                        <Space direction="horizontal">
                                            <Button
                                                type="primary"
                                                className='ant-btn-primary-blue'
                                                icon={<FilePdfOutlined />}
                                                onClick={()=>this.getPDF(item._id)}
                                            />

                                            <Button
                                                type="primary"
                                                className='ant-btn-primary-green'
                                                icon={<IconEye />}
                                                onClick={() => this.setState({ modalTransaccionDetalle: true, transaccion_id: item._id })}
                                            />

                                            <Button
                                                type="primary"
                                                className='btn-secondary'
                                                icon={<IconBtnEdit />}
                                                onClick={() => this.setState({ modalTransaccion: true, transaccion_id: item._id })}
                                            />

                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar esta transacción?"
                                                onConfirm={() => axios.delete('/transacciones/delete', { params: { id: item._id } }).then((response) => {
                                                    message.success(response?.data?.message)
                                                    this.getTransacciones()
                                                })
                                                    .catch((error) => {
                                                        message.error(error?.response?.data?.message);
                                                        this.getTransacciones();
                                                    })
                                                }
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button type="primary" danger className='btn-delete' title="Eliminar" icon={<IconBtnDelete />} />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}

                />
                
                <ModalTransaccionDetalle
					visible={this.state.modalTransaccionDetalle}
					onCancel={() => {
						this.setState({ modalTransaccionDetalle: false, transaccion: undefined })
						this.getTransacciones(this.state.page)
					}}
					transaccion={this.state.transaccion_id}
					
				/>

				<ModalTransaccion
					visible={this.state.modalTransaccion}
					onCancel={() => {
						this.setState({ modalTransaccion: false, transaccion_id: undefined })
						this.getTransacciones(this.state.page)
					}}
					transaccion_id={this.state.transaccion_id}
					cliente_id={this.props.params?.cliente_id}
				/>
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Compras {...props} />)
}