import { CloseOutlined, WarningOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, } from "@ant-design/icons"
import { Typography, Tag } from 'antd'

import '../Styles/Utils.css'

/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusInversion = ({estatus = 0}) => {

    let steps = {
        0: <Tag className ="tag-inversion-cancelada">CANCELADA</Tag>,
        1: <Tag className ="tag-inversion-pendiente">PENDIENTE</Tag>,
        2: <Tag className ="tag-inversion-pagada">PAGADA</Tag>,
        3: <Tag className ="tag-inversion-ejecutada">EJECUTADA</Tag>,
        4: <Tag className ="tag-inversion-reenvieda">RENVIADA</Tag>,

    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

const renderEstatusReventa = ({estatus = 0}) => {

    let steps = {
        0: <Tag className ="tag-inversion-cancelada">CANCELADA</Tag>,
        1: <Tag className ="tag-inversion-pendiente">EN VENTA</Tag>,
        2: <Tag className ="tag-inversion-pagada">VENDIDA</Tag>,
        3: <Tag className="tag-inversion-cancelada">REQUIERE MODIFICAR</Tag>,
    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}


/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusCliente = ({estatus = 0}) => {

    let steps = {
        0: <Tag className="tag-estatus cancelado">Cancelado</Tag>,
        1: <Tag className="tag-estatus registrado">Registrado</Tag>,
        2: <Tag className="tag-estatus contactado">Contactado</Tag>,
        3: <Tag className="tag-estatus compro">Invirtio</Tag>,
        4: <Tag className="tag-estatus pagado">Pagado</Tag>,

    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}

export {
    renderEstatusInversion,
    renderEstatusCliente,
    renderEstatusReventa
}