import React, { Component } from 'react';
import { Row, Col, Typography, Button, PageHeader, Tag, Layout, message, Card, Space, Tabs, Popconfirm } from 'antd'
import axios from 'axios';
import { BankOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { FaFileContract, FaPenFancy } from "react-icons/fa";
import Decimal from 'decimal.js';

//componentes
import Transacciones from './Listas/TransaccionesInversion';
import Reventas from './Listas/Reventas';
import { renderEstatusInversion } from '../../Utils';
import { IconArrowBack, IconFlag, IconEye, IconBtnDelete, IconPDF, IconMail, IconBtnEdit } from '../../Widgets/Iconos';

import ModalInversion from "../../Admin/Inversiones/ModalInversion"
//css
import '../../../Styles/Modules/Admin/CRM.scss'

const { Content } = Layout;
const { Title, Text } = Typography
const { TabPane } = Tabs
const moment = require('moment')
moment.locale('es');

/**
 *
 *
 * @export
 * @class DetalleInversion
 * @extends {Component}
 * @description Vista donde se muestra la informacion de una inversion de un cliente
 */
class DetalleInversion extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading_mail: false,
            loading: false,
            inversion_id: this.props.params.inversion_id,
            cliente: {},
            inversion: {},
            hacienda: {},
            cantidades: {
				pendientes: 0,
				vendidas: 0,
				total: 0,
				revendidas: 0,
				disponibles: 0,
			}
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.inversion_id) {
            this.getInversion()
            this.getCantidades()
        }
    }

    /**
    *
    * @memberof DetalleInversion
    * 
    * @method getInversion
    * @description Obtiene informacion del Cliente
    */
    getInversion = () => {
        this.setState({ loading: true })
        axios.get('/inversion', {
            params: {
                id: this.props.params.inversion_id,

            }
        }).then(response => {
            let inversion = response.data.data
            console.log("inversion", inversion)
            this.setState({
                inversion: inversion,
                cliente: inversion.cliente_id,
                hacienda: inversion.hacienda_id,
            })

        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información de la inversion")
        }).finally(() => this.setState({ loading: false }))
    }

    getCantidades = () => {

		axios.get('/inversion/cantidades', {
			params: {
				inversion_id: this.props.params.inversion_id
			}
		})
			.then(({ data }) => {
				console.log(data)
				data.data.disponibles = Number(data.data.total - data.data.revendidas)
				this.setState({ cantidades: data.data })
			})
			.catch(err => {

				message.error('No se pudieron cargar las cantidades.')
			})
			.finally()

	}


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getPDF
     * @description Generamos el PDF de la referencia de pago
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/crm/ficha-pago');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('inversion_id', this.state.inversion._id)
        return urlPDF.href
    }


    /**
     *
     *
     * @memberof MatrizCostos
     *
     * @method sendPDF
     * @description Enviamos la cotizacion al cliente
     */
    sendPDF = () => {
        this.setState({ loading_mail: true });
        return axios
            .get("/crm/ficha-pago", {
                params: {
                    inversion_id: this.state.inversion._id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Recibo Enviado");
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al enviar el recibo");
            })
            .finally(() => {
                this.setState({ loading_mail: false });
            });
    };

    /**
     *
     *
     * @memberof MatrizCostos
     *
     * @method sendContrato
     * @description Enviamos la cotizacion al cliente
     */
    sendContrato = () => {
        this.setState({ loading_contrato: true });
        return axios
            .get("/crm/contrato", {
                params: {
                    cliente_id: this.state.cliente._id,
                    inversion_id: this.state.inversion._id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Contrato Enviado");
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 403)
                    message.error(error.response.data.message)
                else
                    message.error("Error al enviar el contrato");
            })
            .finally(() => {
                this.setState({ loading_contrato: false });
            });
    };

    /**
 *
 * @memberof ClientesDetalles
 * 
 * @method getContrato
 * @description Generamos el PDF del estado de cuenta del cliente
 */
    getContrato = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/crm/contrato');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('cliente_id', this.state.cliente._id)
        urlPDF.searchParams.append('inversion_id', this.state.inversion._id)
        return urlPDF.href
    }


    render() {

        const { inversion, cliente, hacienda } = this.state

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<div className="flex-left">
                        <Button ghost onClick={this.back} style={{ border: "none" }}>
                            <IconArrowBack />
                        </Button>
                        Clientes / {cliente.nombre} {cliente.apellido_paterno} / {inversion.folio}
                    </div>}
                />
                <Content className="pd-1">
                    <Card className="card-cliente">
                        <Row gutter={[8]}>
                            <Col xs={16} xl={8} className="flex-left">
                                <Title className="text-title" level={3}>Información de la Inversión</Title>
                            </Col>
                            <Col xs={8} xl={8} className="flex-right">
                                {renderEstatusInversion({ estatus: inversion.estatus })}
                            </Col>
                            <Col xs={24} xl={8} className="flex-right">
                                <Space>
                                    <Button
                                        title="Enviar contrato"
                                        type='primary'
                                        className='btn-primary center'
                                        icon={<FaPenFancy />}
                                        onClick={() => this.sendContrato()}
                                        loading={this.state.loading_contrato}
                                    />
                                    <a href={this.getContrato()} target="_blank">
                                        <Button
                                            title="Ver contrato"
                                            type='primary'
                                            className='btn-primary center'
                                            icon={<FaFileContract />}
                                        />
                                    </a>
                                    <Button
                                        type='primary'
                                        className='btn-primary center'
                                        icon={<IconMail />}
                                        onClick={() => this.sendPDF()}
                                        loading={this.state.loading_mail}
                                    />
                                    <a href={this.getPDF()} target="_blank">
                                        <Button
                                            type='primary'
                                            className='btn-primary center'
                                            icon={<IconPDF />}
                                        />
                                    </a>
                                    <Button
                                        type='primary'
                                        className='btn-primary center'
                                        icon={<IconBtnEdit />}
                                        onClick={() => { this.setState({ modalInversionVisible: true }) }}
                                    />
                                    <Popconfirm
                                        placement="topRight"
                                        title="¿Deseas eliminar esta inversión?"
                                        onConfirm={() => axios.delete('/inversion', {
                                            params: { id: inversion._id }
                                        }).then((response) => {
                                            message.success("Inversión eliminada")
                                            this.back()
                                        }).catch((error) => {
                                            message.error("Error al eliminar la inversión");
                                        }).finally(() => {
                                            this.getInversiones()
                                        })
                                        }
                                        okText="Si"
                                        cancelText="No"
                                    >
                                        <Button type="primary" className='btn-delete center' danger title="Eliminar" icon={<IconBtnDelete />} />

                                    </Popconfirm>
                                </Space>
                            </Col>
                            <Col xs={24} md={12} className="flex-left-column">
                                <Text className="text-label">Hacienda</Text>
                                <Text ellipsis className="text-info">{hacienda.nombre}</Text>
                            </Col>
                            <Col xs={24} md={12} className="flex-left-column">
                                <Text className="text-label">Plantas Compradas</Text>
                                <Text ellipsis className="text-info">{inversion.cantidad?.toMoney(true)}</Text>
                            </Col>
                            <Col xs={12} md={8} xl={5} className="flex-left-column">
                                <Text className="text-label">Total Vendido</Text>
                                <Text ellipsis className="text-info">$ {inversion.monto?.toMoney(true)} {inversion.moneda}</Text>
                            </Col>
                            <Col xs={12} md={8} xl={5} className="flex-left-column">
                                <Text className="text-label">Monto Pagado</Text>
                                <Text ellipsis className="text-green">$ {inversion.monto_pagado?.toMoney(true)} {inversion.moneda}</Text>
                            </Col>
                            <Col xs={12} md={8} xl={5} className="flex-left-column">
                                <Text className="text-label">Cantidad de  Plantas</Text>
                                <Text ellipsis className="text-info">
                                    {/* {Decimal(inversion?.cantidad || 0).sub(inversion?.cantidad_revendidas || 0).toNumber().toMoney(true)} */}
                                    {inversion.cantidad_revendidas > 0 ? (
                                        <>
                                            <Text ellipsis className="text-info">{this.state.cantidades.disponibles || 0} Actualmente</Text><br/>
                                            <Text ellipsis className="text-info">{this.state.cantidades.vendidas || 0} Vendidas</Text><br/>
                                            <Text ellipsis className="text-info">{this.state.cantidades.revendidas || 0} en Venta</Text><br/>
                                        </>
                                    ) : (
                                        <Text ellipsis className="text-info">{this.state.cantidades.total || 0}</Text>
                                    )}
                                </Text>
                            </Col>
                            <Col flex="auto" className="flex-left-column">
                                <Text className="text-label">Monto Pendiente</Text>
                                <Text ellipsis className="text-red">$ {inversion.monto_pendiente?.toMoney(true)} {inversion.moneda}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={5} className="flex-left-column">
                                <Text className="text-label">Ultimo Precio Planta</Text>
                                <Text ellipsis className="text-info">$ {inversion.precio_planta?.toMoney(true)} {inversion.moneda}</Text>
                            </Col>
                        </Row>
                        {
                            inversion.reventa_id ?
                                <Row gutter={[8]} className='mt-2'>
                                    <Col xs={24} xl={12} className="flex-left">
                                        <Title className="text-title" level={3}>Información de la Reventa</Title>
                                    </Col>
                                    <Col xs={24} xl={12} className="flex-right">
                                        <Title className="text-title" level={3}>{inversion?.reventa_id?.folio}</Title>
                                    </Col>
                                    <Col xs={24} md={10} className="flex-left-column">
                                        <Text className="text-label">Inversion Original</Text>
                                        <Text ellipsis className="text-info">{inversion?.reventa_id?.inversion_original_id?.folio}</Text>
                                    </Col>
                                    <Col xs={12} md={7} xl={7} className="flex-left-column">
                                        <Text className="text-label">Cliente Original</Text>
                                        <Text ellipsis className="text-info">{inversion?.reventa_id?.usuario_id?.nombre} {inversion?.reventa_id?.usuario_id?.apellido_paterno} {inversion?.reventa_id?.usuario_id?.apellido_materno}</Text>
                                    </Col>
                                    <Col xs={12} md={5} xl={5} className="flex-left-column">
                                        <Text className="text-label">Cantidad Comprada</Text>
                                        <Text ellipsis className="text-green">{inversion.cantidad?.toMoney(true)}</Text>
                                    </Col>

                                </Row> : <></>
                        }

                    </Card>
                    <Card className="card-financiero">
                        <Row gutter={[8]}>
                            <Col xs={24} md={12} xl={6} className="flex-column">
                                <Text className="text-label">Nombre</Text>
                                <Text ellipsis className="text-info">{cliente.nombre} {cliente.apellido_paterno}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-column">
                                <Text className="text-label">Fecha Nacimiento</Text>
                                <Text ellipsis className="text-info">{cliente.fecha_nacimiento ? moment(cliente.fecha_nacimiento).format("DD-MM-YYYY") : '-'}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={7} className="flex-column">
                                <Text className="text-label">Email</Text>
                                <Text ellipsis className="text-info">{cliente.email}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={5} className="flex-column">
                                <Text className="text-label">Teléfono</Text>
                                <Text ellipsis className="text-info">{cliente.telefono ?? '-'}</Text>
                            </Col>
                        </Row>
                    </Card>
                    <Tabs defaultActiveKey="transacciones" destroyInactiveTabPane>
                        <TabPane tab="Transacciones" key="transacciones">
                            <Transacciones
                                inversion_id={this.state.inversion_id}
                                getInversion={() => this.getInversion()}
                                cliente_id={this.state.cliente._id}
                                cliente={this.state.cliente}
                            />
                        </TabPane>
                        <TabPane tab="Reventas" key="reventas">
                            <Reventas
                                inversion_id={this.state.inversion_id}
                                onClose={() => this.getInversion()}
                                cliente_id={this.state.cliente._id}
                                cliente={this.state.cliente}
                            />
                        </TabPane>
                    </Tabs>
                </Content>
                <ModalInversion
                    visible={this.state.modalInversionVisible}
                    onClose={(flag) => {
                        this.componentDidMount()
                        this.setState({ modalInversionVisible: false })
                    }}
                    inversion_id={this.state.inversion_id}
                    cliente={this.state.cliente}
                />
            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()

    return <DetalleInversion  {...props} params={params} navigate={navigate} />
}