import React, { Component } from "react";
import { Row, Col, Typography, Card, Radio, Spin, Checkbox, message } from 'antd';
import axios from 'axios'
import { BankOutlined } from '@ant-design/icons';
import { LightenDarkenColor } from 'lighten-darken-color';

//componentes
import PagoTarjeta from './PagoTarjeta';
import Timer from '../../Widgets/Timer/Timer';
import { IconAgabe, IconPDFColor, IconCreditCard } from '../../Widgets/Iconos';

//css
import '../../../Styles/Global/modales.css'

const { Title, Text, Paragraph } = Typography;

/**
 * @export
 * @class ModalFicha
 * @extends {Component}
 */
export default class ModalFicha extends Component {

    constructor(props) {
        super(props);
        this.state = {
            vista: 1,
            loading: false,
            token: null
        }
    }


    componentDidMount() {

    }



    /**
     *
     * @memberof ModalFicha
     * 
     * @method getPDF
     * @description Generamos el PDF de la referencia de pago
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/crm/ficha-pago');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('inversion_id', this.props.ficha?.inversion?._id)
        return urlPDF.href
    }

    /**
     *
     * @memberof ModalFicha
     * 
     * @method onChange
     * @description Generamos el PDF de la referencia de pago
     */
    onChange = (e) => {
        this.setState({ vista: e.target.value })

    }




    render() {

        console.log("ñ", this.props.ficha)

        return (
            <>
                <Card className="card-hacienda-modal" style={{ background: `linear-gradient(204.67deg, ${LightenDarkenColor(this.props.hacienda?.color, 50)} 9.43%, ${this.props.hacienda?.color} 84.49%)` }}>
                    <Row>
                        <Col span={19}>
                            <Title>{this.props.hacienda?.nombre}</Title>
                        </Col>
                        <Col span={5} className="center">
                            <IconAgabe />
                        </Col>
                    </Row>
                </Card>
                <Title level={2} className="text-cyan text-center">
                    <Timer start={this.props.ficha?.createdAt} duracion={1} />
                </Title>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={12}>
                            <Text className="text-input" style={{ fontSize: 20 }}>Cantidad de Plantas:</Text>
                        </Col>
                        <Col span={12} align="end">
                            <Text className="text-result" style={{ fontSize: 20 }}>{this.props.ficha?.inversion?.plantas_compradas}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Text className="text-input" style={{ fontSize: 20 }}>Precio por Planta</Text>
                        </Col>
                        <Col span={12} align="end">
                            <Text className="text-result" style={{ fontSize: 20 }}>$ {this.props.ficha?.inversion?.precio_planta?.toMoney(true)} <small>{this.props.ficha?.inversion?.moneda}</small></Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Text className="text-input" style={{ fontSize: 24 }}>Total a Invertir</Text>
                        </Col>
                        <Col span={12} align="end">
                            <Text className="text-result" style={{ fontSize: 24 }}>$ {this.props.ficha?.inversion?.monto} <small>{this.props.ficha?.inversion?.moneda}</small></Text>
                        </Col>
                    </Row>
                    <Row className="mt-2 " align="center" justify="center">
                        <Radio.Group className="radio-payment-selection" defaultValue={1} onChange={this.onChange}>
                            <Radio.Button className="button-payment-selection" value={1}>
                                <BankOutlined className="icon" style={{ color: "currentcolor", }} width={40} />
                                <span className="text-payment-selection">Transferencia Bancaria</span>
                            </Radio.Button>
                            {
                                this.props.ficha?.moneda === "USD" && (
                                    <Radio.Button className="button-payment-selection" value={2}>
                                        <IconCreditCard className="icon credit-card" style={{ color: "currentcolor", }} color="currentcolor" width={40} />
                                        <span className="text-payment-selection">Pago con Tarjeta</span>
                                    </Radio.Button>
                                )
                            }
                        </Radio.Group>
                    </Row>
                    {this.state.vista === 1 ? <>
                        <BankOutlined className="icon" style={{ color: "currentcolor", }} width={40} />
                        <br />
                        <Title level={5}>Pago mediante transferencia bancaria</Title>
                        <Paragraph style={{ textAlign: "justify" }}>Se ha enviado un correo electrónico a tu email registrado que contiene una ficha de pago. Posteriormente, nosotros confirmaremos recepción de tu depósito y te haremos envio de tu factura.</Paragraph>
                        <Title level={4} className="text-cyan text-center">Descargar Ficha de Pago</Title>
                        <Row className="mt-1">
                            <Col xs={24} className="center">
                                <a href={this.getPDF()} target="_blank">
                                    <IconPDFColor />
                                </a>
                            </Col>
                        </Row>
                        <Row className="mt-1 mb-2">
                            <Col xs={24} className="center">
                                <small className="text-gray">Puedes confirmar o cancelar la compra desde ‘Mi Cuenta’.</small>
                            </Col>
                        </Row>
                        {this.props.ficha?.moneda === "MXN" && (
                            <Row className="mt-1 mb-2">
                                <Col xs={24} className="center">
                                    <Title level={4} className="text-cyan text-center">Datos para transferencia bancaria</Title>
                                </Col>
                                <Col xs={24}>
                                    <Row>
                                        <Col span={12}>
                                            <Text className="text-input" style={{ fontSize: 20 }}>BANCO</Text>
                                        </Col>
                                        <Col span={12} align="end">
                                            <Text className="text-result" style={{ fontSize: 20 }}>BBVA</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <Row>
                                        <Col span={12}>
                                            <Text className="text-input" style={{ fontSize: 20 }}>CUENTA</Text>
                                        </Col>
                                        <Col span={12} align="end">
                                            <Text className="text-result" style={{ fontSize: 20 }}>0119443347</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <Row>
                                        <Col span={12}>
                                            <Text className="text-input" style={{ fontSize: 20 }}>CLABE</Text>
                                        </Col>
                                        <Col span={12} align="end">
                                            <Text className="text-result" style={{ fontSize: 20 }}>012384001194433474</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <Row>
                                        <Col span={12}>
                                            <Text className="text-input" style={{ fontSize: 20 }}>DIRECCIÓN</Text>
                                        </Col>
                                        <Col span={12} align="end">
                                            <Text className="text-result" style={{ fontSize: 20 }}>Revolución Mexicana 75, San Miguel El Alto
                                                Centro 47140, San Miguel el Alto, Jalisco, Mexico</Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </> : <>
                        <Paragraph>También se tiene la opción de realizar el pago con una tarjeta de crédito o débito</Paragraph>
                        <Paragraph>Se añadira un 3% de comisión*</Paragraph>
                        <Row>
                            <Col span={24}>
                                <PagoTarjeta ficha={this.props.ficha} onClose={this.props.onCancel} />
                            </Col>
                        </Row>
                    </>}
                </Spin>
            </>
        )
    }
}



