import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, InputNumber, DatePicker, Upload, Checkbox, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ColorPicker from '../../../Widgets/ColorPicker';
import Mapa from '../../../Widgets/Mapa/Mapa';

import Busqueda from '../../../Widgets/Busqueda';
import * as turf from "@turf/turf";

const { Title } = Typography;
const { Option } = Select;
const moment = require('moment');
const axios = require('axios').default;


/**
 *
 *
 * @class ModalHacienda
 * @extends {Component}
 */
class ModalHacienda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
            imagenes: [],
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id !== undefined) {
            this.get()
        }
        window.s = this.modalRef
    }

    mapReact = React.createRef();


    /**
    * @memberof ModalHacienda
    * @method get
    * @description Obtenemos una hacienda del APi
    */
    get = () => {
        this.setState({ loading: true })

        axios.get('/hacienda', {
            params: {
                _id: this.props.id
            }
        }).then(response => {
            let data = response.data.data;

            this.setState({
                imagenes: data.imagenes
            })

            this.modalRef.current?.setFieldsValue({
                nombre: data.nombre,
                descripcion: data.descripcion,
                color: data.color,
                fecha_creacion: data.fecha_creacion ? moment(data.fecha_creacion, 'YYYY-MM-DD') : undefined,
                imagenes: data.imagenes.map(({ name, filename }, index) => ({
                    uid: index,
                    name: name,
                    filename: filename,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + filename
                })),
                estatus: data.estatus,
                hectareas: data.hectareas,
                paneles: data.paneles,
                disponible: data.disponible,
                precio: data.precio,
                plantas: data.plantas,
                poligono: data.poligono,
                localizacion: data.localizacion,
                arrendatario: data.arrendatario,
                pago_diferido: data.pago_diferido,
                periodicidad: data.periodicidad,
                solares: data.solares,
                fecha_arrendacion: data.fecha_arrendacion ? moment(data.fecha_arrendacion) : null,
                fecha_pago: data.fecha_pago ? moment(data.fecha_pago) : null,
            })
        }).catch(error => {
            message.error('Error al cargar Hacienda')

        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalHacienda
     * @method onFinish
     * @param {object} values - Objecto que contiene los valores del formulario
     * @description Cuando se envia el formulario
     * 
     */
    onFinish = (values) => {

        if (Array.isArray(values.imagenes))
            values.imagenes = values.imagenes.map(image => ({
                name: image.name,
                filename: image.filename,
                image
            }))

        if (this.props.id) {
            this.update(values)
        } else {
            this.add(values)
        }
    }


    /**
     * @memberof ModalHacienda
     * @method add
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     * @description Agregamos una hacienda
     */
    add = (values) => {

        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.post('/hacienda/add', {
                ...values
            }).then(response => {
                message.success('Hacienda creada')
                this.props.onSuccess()
            }).catch(error => {
                console.log("error", error);

                message.error('Error al crear la hacienda.')
            }).finally(() => {
                this.setState({ loading: false })
            })
        })
    }

    /**
     * @memberof ModalHacienda
     * @method update
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     * @description Actualizamos los valores de la hacienda
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/hacienda/update', {
            ...values,
            _id: this.props.id,
        }).then(response => {
            message.success('Hacienda Actualizada')
            this.props.onSuccess()
        }).catch(error => {
            let msg = 'Error al actualizar Hacienda'
            if (error.response.status === 403)
                msg = error.response.data.message
            message.error(msg)

        }).finally(() => this.setState({ loading: false }))
    }



    /**
     *
     * @memberof ModalHacienda
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        this.setState({ loading: true })

        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }
        }


        if (file.status === "done") {
            let imagenes = this.state.imagenes

            imagenes.push({
                name: file.response.name,
                filename: file.response.filename,
            })

            this.setState({ imagenes })

            e.file.filename = file.response.filename

        }
        this.setState({ loading: false })
        return e.fileList;
    };

    /**
     *
     * @memberof ModalHacienda
     * @param {string} file - Nombre del archivo
     * @method deleteImage
     * @description Quita el archivo del state de comprobantes para ponerlos en files_to_delete, asi cuando el usuario guarde, se eliminan, y no se borren automaticamente
     */
    deleteImage = (file) => {
        let imagenes = this.state.imagenes
        let index = imagenes.findIndex(img => img.filename === file.filename)
        if (index != -1) {
            imagenes.splice(index, 1)
        }
    }

    /**
     *
     *
     * @param {object} polygon - Objecto con los datos trigonometricos de la hacienda
     * @memberof ModalHacienda
     * @method setHectareas
     * @description Calcula y establece las heactareas cuadradas de un poligono
     */
    setHectareas = (polygon) => {
        if (!polygon?.features || polygon?.features?.length < 1) {
            this.modalRef.current?.setFieldsValue({
                hectareas: undefined
            })

            return
        }

        let area = turf.area(polygon)
        area = Number((area * 0.0001).toFixed(2)) // m2 a h2

        this.modalRef.current?.setFieldsValue({
            hectareas: area
        })
    }

    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalRef}
                onFinish={this.onFinish}
                className="pd-1"

                onValuesChange={(values, allvalues) => {
                    if (values.localizacion) {


                        this.mapReact.current?.map?.current?.setCenter(values?.localizacion?.center)

                    }



                    if (values.poligono) {
                        this.setHectareas(values.poligono.polygon)
                    }
                }}

                onFinishFailed={e => {

                    try {
                        console.log("e",)
                        document.querySelector(".ant-modal-wrap").scrollTo({
                            top: 0,
                            left: document.querySelector("#" + e.errorFields[0].name[0]),
                            behavior: 'smooth'
                        })
                    } catch (error) {
                        console.log("error", error)
                    }
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" maxLength={50}></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Nombre de Arrendatario"
                                name="arrendatario"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" maxLength={50}></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la descripción"
                                }]}
                            >
                                <Input.TextArea placeholder="Descripción" maxLength={100} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Fecha de Creación"
                                name="fecha_creacion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha de creación"
                                }]}
                            >
                                <DatePicker className="width-100" placeholder="Seleccionar" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Hectáreas"
                                name="hectareas"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese las hectareas"
                                }]}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Solares"
                                name="solares"

                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Precio"
                                name="precio"
                                rules={[{
                                    required: true,
                                    message: "Por favor,ingrese el Precio"
                                }]}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Plantas"
                                name="plantas"
                                rules={[{
                                    required: true,
                                    message: "Por favor,ingrese la cantidad de plantas"
                                }]}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Disponible"
                                name="disponible"
                                rules={[{
                                    required: true,
                                    message: "Por favor,ingrese la cantidad disponible de plantas"
                                }]}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Estatus"
                                name="estatus"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un estatus"
                                }]}
                            >
                                <Select>
                                    <Option value={1}> Sin Vender </Option>
                                    <Option value={2}> Preventa </Option>
                                    <Option value={3}> Vendida </Option>
                                    <Option value={4}> Completa </Option>
                                    <Option value={5}> Cooming Soon </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Fecha de Arrendación"
                                name="fecha_arrendacion"
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Fecha de Pago"
                                name="fecha_pago"
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Color"
                                name="color"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un color"
                                }]}
                            >
                                <ColorPicker />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={16} >
                            <Form.Item
                                label="Periodo de Pago"
                                name="periodicidad"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione un periodo"
                                }]}
                            >
                                <Select>
                                    <Option value={1}> Semanal </Option>
                                    <Option value={2}> Quincenal </Option>
                                    <Option value={3}> Mensual </Option>
                                    <Option value={4}> Trimestral </Option>
                                    <Option value={5}> Semestral </Option>
                                    <Option value={6}> Anual </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Dirección de la Propiedad"
                                name="localizacion"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la dirección de la propiedad"
                                }]}
                            >
                                <Busqueda />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Localización de la Propiedad"
                                name="poligono"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la localización de la propiedad"
                                }]}
                            >
                                <Mapa ref={this.mapReact} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Imagenes de la Hacienda"
                                name="imagenes"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                            >
                                <Upload
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={true}
                                    listType="picture-card"
                                    accept="image/*"
                                    onRemove={this.deleteImage}
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Subir Archivo</div>
                                    </div>
                                </Upload>
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Hacienda</Title>
        </div>
        <ModalHacienda {...props} />
    </Modal>

}