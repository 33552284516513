import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import axios from 'axios'
import { Auth } from './Routes/Middlewares/Auth'
import Routes from './Routes'
import './App.css';
import './Styles/Global/global.scss'
import { Modal } from 'antd';

// antd/dist/antd.dark.min.css
// import 'antd/dist/antd.dark.min.css'

axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.withCredentials = true
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
	config.headers.Authorization = sessionStorage.getItem("token")
	return config;
})


// Add a response interceptor
axios.interceptors.response.use(function (response) {
	if (response?.headers?.authorization)
		sessionStorage.setItem("token", response?.headers?.authorization)
	return response;
}, function (error) {

	// console.log("error", error.response.status)
	if (error.response.status == 401) {
		if (window.location.href.includes("/admin") || window.location.href.includes("/customer"))
		Modal.error({
			title: "Debe iniciar sesión para continuar",
			content: "Para poder acceder a Zeus, debe iniciar sesión",
			cancelButtonProps:{
				style: {
					display: 'none'
				}
			},
			okText: "Ir a Iniciar Sesión", 
			onOk: () => window.location.replace("/") 
		})
	}

	return Promise.reject(error);
});

/**
 *
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: 0,
		}


	}

	componentDidMount() {

		if (sessionStorage.getItem('token') != null || sessionStorage.getItem('token' != undefined)) {
			axios.get('/user/logged', {
				headers: { Authorization: sessionStorage.getItem('token') },
				params: {
					cliente: true
				}
			}).then(({ data }) => {
				console.log("data", data.data);
				this.setUser(data.data)
			}).catch((error) => {
				console.log('error log==>', error.response.status)
			})
		}

	}

	setUser = (user) => {
		this.setState({ user })
	};


	render() {
		const { setUser } = this;
		const { user } = this.state;
		return (
			<BrowserRouter>
				<User.Provider value={user}>
					<SetUser.Provider value={setUser}>
						<Routes />
					</SetUser.Provider>
				</User.Provider>
			</BrowserRouter>
		);
	}
}

export default App;
